
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
    createClassListState,
    createInstructorState,
    createConeState,
    selectRegistrations,
    fetchClassListContent,
    selectClassLists,
    campId,
    saveClassListContent,
    pullLastSavedClassContent,
    registrationsStatus,
    classListsStatus,
    classListsError,
    updatedTime,
    instructorArray,
    conesArray,
    errorArray,
    auxInstructorArray,
    rearRideramArray,
    rearRiderpmArray,
    validationErrorObj,
    firstSelectClassLists
} from "../../redux/classListSlice";


// Import Type 
import { Sites, classListRegistrationState, newClassListRegistrations, siteContainerProps } from '../../common/types';

import { selectInstructor, fetchInstructorsData } from '../../redux/instructorSlice';
import { AppDispatch } from '../../redux/store';
import { fetchSiteContent, selectSite } from "../../redux/siteSlice";

// Functions 
import { createClassDropDown } from "../../functions/ClassListsFeatures/CreateDropDown";
import CompareClassListsV2 from "../../functions/ClassListsFeatures/ClassListSortingFunctions/CompareSortedSavedClassLists/CompareClassListsV2";
import { CreateAllTimesClassListsV2 } from "../../functions/ClassListsFeatures/ClassListSortingFunctions/InitialSortClassListsV2/CreateAllTimesClassLists";

// Import Features 
import ClassList from "./ClassList";
import ClassListValidation from "./ClassListValidation";
import { PrintFeature } from "../PrintFeatures/PrintFeature";
import ClassListErrorDisplay from "./ClassListErrorDisplay";

// Import Components
import ModalPopup from "../../Components/ModalPopup"
import { checkInstructorsArray } from "../../functions/ClassListsFeatures/ClassListValidationFunctions/CheckInstructorsArray";
import { errorMessageCheck } from "../../functions/ClassListsFeatures/ClassListValidationFunctions/ErrorMessageCheck";
import { CreateCamperValues } from "./CreateCamperValues";
import { checkUserContent, logUserOut, userDataError } from "../../redux/userSlice";



export type classListType = {
    [key:string]: any[]
}

function ClassListsContainer({ hasStateBeenChanged, setHasStateBeenChanged }: siteContainerProps) {
    // URL Params
    let { classId } = useParams();
    let { siteId } = useParams();

    // ParseInt for comparisons
    let classListId = classId ? parseInt(classId) : 0;
    // Local State
    const [classGroupVisible, setClassGroupVisible] = useState<string>("am");
    const [classLists, setClassLists] = useState<newClassListRegistrations>();
    const [instructors, setInstructors] = useState<object>({});
    const [cones, setCones] = useState<object>({});
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [classListValid, setClassListValid] = useState<boolean>(false);
    const [error, setError] = useState(Array<Array<string>>);
    const [missingInstructor, setMissingInstructor] = useState(Array<string>);
    const [validations, setValidations] = useState<object>();
    const [isloaded, setIsLoaded] = useState<boolean>(false);
    const [currentSiteData, setcurrentSiteData] = useState<Sites>();
    const [sortErrorMessage, setSortErrorMessage] = useState("Please wait while we sort your class lists!");
    const [isComboCamp, setIsComboCamp] = useState<boolean>(false);
    const [autoSavedMessage, setAutoSavedMessage] = useState("");
    // Global State
    const registrationContent = useSelector(selectRegistrations);
    const classListContent:classListRegistrationState = useSelector(selectClassLists);
    const firstclassListContent = useSelector(firstSelectClassLists);
    const registrationStatus = useSelector(registrationsStatus);
    const classListStatus = useSelector(classListsStatus);
    const classListError = useSelector(classListsError);
    const selectedCampId = useSelector(campId);
    const instructorState = useSelector(selectInstructor);
    const savedInstructorArray = useSelector(instructorArray);
    const savedConesArray = useSelector(conesArray);
    const savedAuxInstructorArray =  useSelector(auxInstructorArray);
    const savedRearRideramArray =  useSelector(rearRideramArray);
    const savedRearRiderpmArray =  useSelector(rearRiderpmArray);
    const validationError = useSelector(validationErrorObj);
    const siteData = useSelector(selectSite);
    const errors = useSelector(errorArray);
    const updatedTimeStamp = useSelector(updatedTime);
    const userEmail = useSelector((state: any) => state.user.email);
    const userError = useSelector(userDataError);

    const [programTypeFromURL, setProgramTypeFromURL] = useState<string>("");

    const dispatch: AppDispatch = useDispatch<AppDispatch>();

    const [auxInstructorState, setAuxInstructorState] = useState<any>(
        { setup1: "", setup2: "", takedown1: "", takedown2: "" }
    );
    const [rearRideram, setRearRideram] = useState<any[]>([{ id: 'rr-am1', value: '' }]);
    const [rearRiderpm, setRearRiderpm] = useState<any[]>([{ id: 'rr-pm1', value: '' }]);

    // Get site info

    // Call to redux for site data if not loaded and get instructor list
    useEffect(() => {
        dispatch(fetchSiteContent())
        dispatch(fetchInstructorsData(siteId))
        if (siteData.length > 0) {
            const currentSite:any[] = siteData.map((site: Sites) => {
                return (site['siteId'] === siteId) ? site : undefined;
            }).filter(function (element) {
                return element !== undefined;
            });
            setcurrentSiteData(currentSite[0])
            setIsComboCamp(currentSite[0] ? currentSite[0]['program'].includes('Combo'): false)
        }
    }, [siteData.length, currentSiteData])

    // Fetch New Registration Data if no data
    useEffect(() => {
        if(classListId === 0){

        }else{
            if (registrationStatus === "idle" || selectedCampId !== classListId) {
                dispatch(fetchClassListContent(classListId))
            }
        }
    }, [registrationStatus, dispatch, classListId, selectedCampId])

    // Get ClassLists if they don't exist already
    let classListRegistration:any = {};
    let classListContentLength = classListContent ? Object.values(classListContent).length : 0;
    useEffect(() => {
        if (classListId !== selectedCampId) {
            setIsLoaded(false)
        }
    },[classListId, selectedCampId])

    // // Set initial group to pm if no am classlists 
    const setInitialClassGroup = (classListContent:classListRegistrationState) => {
        let classPlanTimeKey = Object.keys(classListContent)

        if (classPlanTimeKey.includes('am')) {
            setClassGroupVisible('am')
        } else {
            setClassGroupVisible('pm')
        }
    }
    setTimeout(() => {
        dispatch(checkUserContent())
        if (userError !== undefined) {
            dispatch(logUserOut())
        }
    }, 300000)
    // // Fetch classList from saved classplan api,  if no data create class list
    useMemo(() => {
        if (classListStatus !== "fulfilled" || selectedCampId !== classListId) {
            dispatch(pullLastSavedClassContent(classListId))
        }
        if (Object.keys(registrationContent).length > 0) {
            classListRegistration = CreateAllTimesClassListsV2(registrationContent)
            if (classListError === "No Saved State") {
                dispatch(createClassListState(classListRegistration))
            }
            if (classListError === "Saved State Loaded") {
                let newClass;
                    // Function to transform the data structure
                    const restructureSavedData = (oldData: any[]) => {
                        let newData: newClassListRegistrations[] = []; // Initialize newData with an empty array
                        for (const key in oldData) {
                            if (Object.hasOwnProperty.call(oldData, key)) {
                                if(Object.keys(oldData[key]).includes("registrations")){
                                    setProgramTypeFromURL( window.location.href.split("/")[4].replace("%20", " ").replace("-", "/"));
                                
                                newData[key] = {
                                    [programTypeFromURL]: { registrations: [], classLists: [], cancelled: [] },
                                    [programTypeFromURL]: { registrations: [], classLists: [], cancelled: [] }
                                };
                                newData[key][programTypeFromURL] = oldData[key];
                                newData[key][programTypeFromURL] = oldData[key];
                            }else{
                                newData = oldData
                            }
                            }
                        }
                        return newData;
                    }
                    // Call the function to transform the data
                    if(Object.values(firstclassListContent).length > 0){
                        const savedData = restructureSavedData(firstclassListContent);
                        newClass = CompareClassListsV2(classListRegistration, savedData)
                    }else{
                        newClass = classListRegistration
                    }
                dispatch(createClassListState(newClass))
            }
        }
        if(classListContent){
         setInitialClassGroup(classListContent)
        }
    }, [registrationContent, classListStatus, isloaded])

    // // Change view between time periods
    const switchVisibleGroup = (e: any) => {
        setClassGroupVisible(e.target.value);
    };

    // //Set ClassLists by time period is ClassList Object 
    useEffect(() => {
        if (classListContentLength > 0 && classListContent && classListContent[classGroupVisible as keyof typeof classListContent]) {
            let classList:any = classListContent[classGroupVisible as keyof typeof classListContent]['classLists'] ? classListContent[classGroupVisible as keyof typeof classListContent]['classLists'] : classListContent[classGroupVisible as keyof typeof classListContent];
            setClassLists(classList);
            setIsLoaded(true);
            if (Object.keys(validationError).length) {
                setValidations(validationError);
            }
        } else {
            setIsLoaded(false)
        }
    }, [classListContentLength, classGroupVisible, classListContent]);

    // restructure legacy instructor and cones array
    const restructureSavedIntructorsCones = (state:any) => {
        const programTypeFromURL = window.location.href.split("/")[4].replace("%20", " ").replace("-", "/");
        let newData:any[] = [];
        Object.entries(state).forEach(([classTime, value]:[string, any]) => {
            Object.keys(value).forEach((key: string) => {
                if(Number.isNaN(Number(key))){
                    newData = {
                        ...newData,
                        [classTime as keyof typeof newData]: value 
                    };
                    
                }else{
                    newData = {
                        ...newData,
                        [classTime as keyof typeof newData]: {
                            [programTypeFromURL]: value
                        }
                    };
                }
            });
                
        })
        return newData
    }
    // Set users and cones from saved state
    useEffect(() => {
        setInstructors(restructureSavedIntructorsCones(savedInstructorArray))
        setCones(restructureSavedIntructorsCones(savedConesArray))
    }, [savedInstructorArray, savedConesArray]);

    // Set aux and rear-rider from saved state
    useEffect(() => {
        if(savedAuxInstructorArray){
         setAuxInstructorState(savedAuxInstructorArray)
        }
        if(savedRearRideramArray && savedRearRideramArray.length > 0){
            setRearRideram(savedRearRideramArray)
        }
        if(savedRearRiderpmArray && savedRearRiderpmArray.length > 0){
            setRearRiderpm(savedRearRiderpmArray)
        }
    }, [savedAuxInstructorArray, savedRearRideramArray,savedRearRiderpmArray]);

    // // Set errors from global state into local state
    useEffect(() => {
        setError(errors)
    }, []);

    // // if loading data takes more then 10 seconds change loading message 
    setTimeout(() => {
        setSortErrorMessage("There may be something wrong with the app. Please refresh or contact your admin")
    }, 10000)

    useEffect(() =>{
        setTimeout(() => {
            saveClassLists()
        }, 1080000000)
    },[true])

    // // This function creates cones, instructors and validation arrays 
    const handleStateArrayUpdate = (e: ChangeEvent<HTMLInputElement>, stateArray:any, setStateArray:Function, classGroupVisible:string, programType:string, itemToSet:any) => {        
        setHasStateBeenChanged(true)

        if (e.target.className === "validations") {
            if (stateArray && stateArray[classGroupVisible] && stateArray[classGroupVisible][programType]) {
                setStateArray({
                    ...stateArray, 
                    [classGroupVisible]: {
                        ...stateArray[classGroupVisible], 
                        [programType]:{
                            ...stateArray[classGroupVisible][programType],
                                [e.target.id]: { ...stateArray[classGroupVisible][programType][e.target.id], ...itemToSet[e.target.id] }
                            }
                        }
                })
            } else {
                setStateArray({ [classGroupVisible]: {[programType]: itemToSet } });
            }

        } else {
             setStateArray({ 
                ...stateArray, 
                [classGroupVisible]: {
                    ...stateArray[classGroupVisible], 
                    [programType]: {
                        ...(stateArray[classGroupVisible]?.[programType] ?? {}),
                        [e.target.id]: itemToSet 
                    } 
                } 
            });
        }
    }

    let hasInstructorMessage = Object.values(missingInstructor).length > 0 ? true : false
    let hasErrorMessage = errorMessageCheck(error);

    const createSavingState = (classList:classListRegistrationState, actionType: string) => {
        let jsonSavingState = {}
        if(actionType === 'save'){
            jsonSavingState = {
                userEmail: userEmail,
                classList: classList,
                instructors: instructors,
                cones: cones,
                errors: error,
                auxInstructorState: auxInstructorState,
                rearRideram: rearRideram,
                rearRiderpm: rearRiderpm
            }
        }else{
            jsonSavingState = {
                userEmail: userEmail,
                classList: classList,
                instructors: {},
                cones: {},
                errors: [],
                auxInstructorState: {},
                rearRideram: [],
                rearRiderpm: []
            }
        }
        let JSONblock = JSON.stringify(jsonSavingState)
        let validationNotes = !classListValid ? JSON.stringify(validations) : null
        let savingState = {
            "campId": selectedCampId,
            "isValid": classListValid,
            "jsonAlerts": validationNotes,
            "jsonDoc": JSONblock
        }
        return savingState
    }

    const saveClassLists = () => {
        checkInstructorsArray(classListContent, instructors, setMissingInstructor)
        dispatch(createInstructorState(instructors))
        dispatch(createConeState(cones))
        dispatch(checkUserContent())
        if (userError !== undefined) {
            dispatch(logUserOut())
        } else {
            setModalVisible(true)
            dispatch(saveClassListContent(createSavingState(classListContent, 'save')));
            (!hasInstructorMessage && !hasErrorMessage) ? setClassListValid(true) : setClassListValid(false)
            setHasStateBeenChanged(false)
            // reloadAfterSave()
        }
       
    }

    const reloadAfterSave = () =>{
        if(modalVisible === false){
            setTimeout(() => {
                    window.location.reload();
            },2000)
        }
    }

    const clearSavedClassPlan = () => {       
        dispatch(createInstructorState({}))
        dispatch(createConeState({}))
        dispatch(checkUserContent())
        dispatch(saveClassListContent(createSavingState(classListRegistration, 'clear')));
        reloadAfterSave()
    }

    // update auxiliary instructor state 
    const updateAuxInstructorState = (id:string, value:string, setState:Function) => {
        setState((prevState:any) => ({
            ...prevState,
            [id]: value
        }));
    }

    const updateRearRiders = (id:string, value:string, setState:Function) => {
        setState((prevState:any)  => 
            prevState.map((rider:any) => 
                rider.id === id ? { ...rider, value: value } : rider
            )
        );
    };

    // add additional rear-rider input fields 
    const addInput = (setState:Function, time:string, state:any) => {
        const newId = `rr-${time}${state.length + 1}`;
        setState([...state, { id: newId, value: '' }]);
    };
    const removeInput = (setState:Function, id:number, state:any) => {
        setState(state.filter((item:any) => item.id !== id));
    };

    return (

        <div className="class-classList container">
            {isloaded ?
                <>
                    <div className="class-classList-header">
                        <h2 className="site-name">{currentSiteData && currentSiteData.name && currentSiteData.name}</h2>

                        <select name="class-select" id="class-select" value={classGroupVisible} onChange={switchVisibleGroup}>
                            {createClassDropDown(classListContent).map((select) => {
                                return <option key={select['key' as keyof typeof select]} value={select['key' as keyof typeof select]}>{select['value' as keyof typeof select]}</option>
                            })}
                        </select>
                    </div>
                    {CreateCamperValues(classListContent, registrationContent, isComboCamp)}
                    {currentSiteData?.program.includes("Bike") &&
                    <div className="aux-instructor-section">
                        <div>
                        <h4>Setup Staff</h4>
                            <select name="instructor-select" id={"setup1"} value={auxInstructorState.setup1 ? auxInstructorState.setup1 : ""} onChange={e => updateAuxInstructorState(e.target.id, e.target.value, setAuxInstructorState)}  className="instructor-select" >
                                <option key={"0-instructor"}>Select your setup staff #1 </option>
                                {instructorState.map((instructor, id) => {

                                    return (
                                        <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>

                                    )
                                })}
                            </select>
                            <select name="instructor-select" id={"setup2"} value={auxInstructorState.setup2 ? auxInstructorState.setup2 : ""} onChange={e => updateAuxInstructorState(e.target.id, e.target.value, setAuxInstructorState)} className="instructor-select" >
                                <option key={"0-instructor"}>Select your setup staff #2</option>
                                {instructorState.map((instructor, id) => {

                                    return (
                                        <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>

                                    )
                                })}
                            </select>
                        </div>
                        <div>
                        <h4>Take down Staff</h4>
                            <select name="instructor-select" id={"takedown1"} value={auxInstructorState.takedown1 ? auxInstructorState.takedown1 : ""} onChange={e => updateAuxInstructorState(e.target.id, e.target.value, setAuxInstructorState)}  className="instructor-select" >
                                <option key={"0-instructor"}>Select your takedown staff #1 </option>
                                {instructorState.map((instructor, id) => {

                                    return (
                                        <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>

                                    )
                                })}
                            </select>
                            <select name="instructor-select" id={"takedown2"} value={auxInstructorState.takedown2 ? auxInstructorState.takedown2 : ""} onChange={e => updateAuxInstructorState(e.target.id, e.target.value, setAuxInstructorState)} className="instructor-select" >
                                <option key={"0-instructor"}>Select your takedown staff #2</option>
                                {instructorState.map((instructor, id) => {

                                    return (
                                        <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>

                                    )
                                })}
                            </select>
                        </div>
                        <div>
                        <h4>Rear riders am</h4>
                            {rearRideram.map((input, index) => (
                                <div key={index} style={{display: "flex", alignItems: "center"}}>
                                    <select
                                        key={input.id}
                                        name="instructor-select"
                                        id={input.id}
                                        value={rearRideram[index].value || ""}
                                        onChange={e => updateRearRiders(e.target.id, e.target.value, setRearRideram)}
                                        className="instructor-select"
                                    >
                                        <option key={"0-instructor"}>Rear-rider am {index + 1}</option>
                                        {instructorState.map((instructor, id) => (
                                            <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>
                                        ))}
                                    </select>
                                    {index > 0 &&
                                    <button className="aux-instructor-button"  onClick={e=> removeInput(setRearRideram, input.id, rearRideram)}>-</button>}
                                 </div>
                            ))}
                            <button className="addMorerrinstructors aux-instructor-button" onClick={e=> addInput(setRearRideram, "am", rearRideram)}>+</button>
                        </div>
                        <div>
                        <h4>Rear riders pm</h4>
                            {rearRiderpm.map((input, index) => (
                             <div key={index} style={{display: "flex", alignItems: "center"}}>
                                    <select
                                        key={input.id}
                                        name="instructor-select"
                                        id={input.id}
                                        value={rearRiderpm[index].value || ""}
                                        onChange={e => updateRearRiders(e.target.id, e.target.value, setRearRiderpm)}
                                        className="instructor-select"
                                    >
                                        <option key={"0-instructor"}>Rear-rider pm {index + 1}</option>
                                        {instructorState.map((instructor, id) => (
                                            <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>
                                        ))}
                                    </select>
                                    {index > 0 &&
                                    <button className="aux-instructor-button"  onClick={e=> removeInput(setRearRideram, input.id, rearRideram)}>-</button>
                                    }
                                </div>
                            ))}
                            <button className="addMorerrinstructors aux-instructor-button"  onClick={e=> addInput(setRearRiderpm, "pm", rearRiderpm)}>+</button>
                        </div>
                    </div>
                    }
                    <div className='camp-header-block'>
                    <p>{autoSavedMessage}</p>
                        <div className="save-class-button-container">
                        <button onClick={e => clearSavedClassPlan()} className="button secondary-button">Clear Class Plan</button>
                            <button onClick={e => saveClassLists()} className="button primary-button">Save Class Plan</button>
                            
                        </div>
                    </div>

                    {modalVisible === true &&
                        <ModalPopup setModalVisible={setModalVisible}>
                            <ClassListValidation
                                error={error}
                                missingInstructor={missingInstructor}
                                saveClassLists={saveClassLists}
                                hasInstructorMessage={hasInstructorMessage}
                                hasErrorMessage={hasErrorMessage}
                                validations={validations}
                                setValidations={setValidations}
                                handleStateArrayUpdate={handleStateArrayUpdate}
                            />
                          
                            {currentSiteData && classListContent &&
                                <PrintFeature
                                    currentSiteData={currentSiteData}
                                    classListContent={classListContent}
                                    instructors={instructors}
                                    instructorState={instructorState}
                                    cones={cones}
                                />
                            }
                        </ModalPopup >
                    }

                    <ClassListErrorDisplay error={error} />


                    {classGroupVisible &&
                        <ClassList
                            handleStateArrayUpdate={handleStateArrayUpdate}
                            setClassLists={setClassLists}
                            classLists={classLists}
                            classGroupVisible={classGroupVisible}
                            error={error}
                            setError={setError}
                            instructors={instructors}
                            setInstructors={setInstructors}
                            setCones={setCones}
                            cones={cones}
                            setHasStateBeenChanged={setHasStateBeenChanged}
                            setClassListValid={setClassListValid}
                            updatedTimeStamp={updatedTimeStamp}
                        />
                    } 
                </>
                :
                <div className="error-message">
                    <p>{sortErrorMessage}</p>
                </div>
            }
            {!isloaded &&
                <div className="loading">
                    <img src="https://cdn.pedalheads.com/images/logos/logo-loading.png" alt="Loading" />
                </div>
            }
        </div> 
    )
}

export default ClassListsContainer;