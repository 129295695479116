import { useSelector } from 'react-redux';
import { selectInstructor } from "../../redux/instructorSlice";
import { InstructorListProps } from '../../common/types';

const InstructorList = ({ index, classGroupVisible, instructors, setInstructors, handleStateArrayUpdate, programType }: InstructorListProps) => {
    const instructorState = useSelector(selectInstructor);

    return (
        <div className='instructor-block'>
            <select name="instructor-select" id={index.toString()} value={instructors[classGroupVisible] && instructors[classGroupVisible][programType] && instructors[classGroupVisible][programType][index] ? instructors[classGroupVisible][programType][index] : ""} onChange={e => handleStateArrayUpdate(e, instructors, setInstructors, classGroupVisible, programType, e.target.value)} className="instructor-select" >
                <option key={"0-instructor"}>Select Your Instructor</option>
                {instructorState.map((instructor, id) => {

                    return (
                        <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>

                    )
                })}
            </select>
        </div>
    )
}

export default InstructorList;