import { useEffect, useState } from "react";
import { fetchInstructorsData, saveInstructorState } from "../../redux/instructorSlice";
import { useDispatch } from 'react-redux';
import { AppDispatch } from "../../redux/store";

export type intructorRoles = {
    id: number,
    name: string

}

export type instructorState = {
    id: string | null,
    name: string,
    role: number | intructorRoles,
    timeOfDay: string

}
 

const CreateInstructorListFeature = ({ siteId, instructorRoles, instructorState }:{ siteId:string, instructorRoles:intructorRoles[], instructorState:instructorState[] }) => {
    // Global State
    const dispatch = useDispatch<AppDispatch>();

    // Local State
    const [instructorList, setInstructorList] = useState<instructorState[]>([{ "id": null, "name": "", "role": 0, "timeOfDay": "AD" }])
    const [clearInstructorListMessage, setClearInstructorListMessage] = useState("")
    const [isSaved, setIsSaved] = useState(false);
    const [hasError, setHasError] = useState(false);

    const handleInputChange = (e:React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>, index:number) => {
        let { name, value } = e.target;
        let postValue:string | number;
        if (name === "timeOfDay_" + index) {
            name = name.split("_")[0]
        }
        if (name === "role") {
            postValue = parseInt(value)
        }else{
            postValue = value
        }

        let list = [...instructorList];
        let instructor = list[index];
        instructor = { ...instructor, [name]: postValue }
        list[index] = instructor

        setInstructorList(list);
    };

    const handleAddClick = () => {
        setInstructorList([...instructorList, { "id": null, "name": "", "role": 0, "timeOfDay": "AD" }])
    }

    const handleRemoveClick = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>, index:number) => {
        event.preventDefault();
        const list = [...instructorList];
        list.splice(index, 1);
        setInstructorList(list);
    };

    const checkRequiredFields = () => {
        instructorList.map((instructor, index) => {
            if (instructor['name'] === ""
                ||
                instructor['role'] === 0
            ) {
                document.getElementById("instructor-info" + index)!.classList.add("missing-content")
            } else {
                document.getElementById("instructor-info" + index)!.classList.remove("missing-content")
            }
        })
        if (document.querySelectorAll('.missing-content').length > 0) {
            setHasError(true)
        } else {
            saveInstructors()
        }
    }

    const saveInstructors = () => {
        setIsSaved(true)
        setHasError(false)
        dispatch(saveInstructorState({ siteId, instructorList }))
        dispatch(fetchInstructorsData(siteId))
        setClearInstructorListMessage("")
    }


    useEffect(() => {
        dispatch(fetchInstructorsData(siteId))
        if (instructorState.length > 0) {
            let instructorData = instructorState.map((instructor: instructorState) => {
                return ({
                    "id": instructor['id'],
                    "name": instructor['name'],
                    "role": instructor['role']['id' as keyof typeof instructor['role']], // Fix: Cast the value to intructorRoles type before accessing the id property
                    "timeOfDay": "AD"
                } as instructorState)
            })
            setInstructorList(instructorData)
        }

    }, [])

    setTimeout(() => {
        setIsSaved(false)
    }, 5000);

    const clearInstructorList = () => {
        setInstructorList([])
        setClearInstructorListMessage("Click save to complete clearing instructor list")
    }

    return (
        <div className="instructor-input-form">
            <div className="instructor-button">

                <button onClick={e => clearInstructorList()} className="button tertiary-button">Clear Instructor List</button>
                {clearInstructorListMessage !== "" &&
                    <div className="saving-icon">{clearInstructorListMessage}</div>}
            </div>
            <form>

                {instructorList.map((instructor, index) => {
                    return (

                        <div className="instructor-input-form-section" key={index} id={"instructor-info" + index}>
                            <label htmlFor="name">Instructor Full Name: </label>
                            <input id={"nameInput_" + index} type="text" name="name" value={instructor['name']} onChange={e => { handleInputChange(e, index) }} />

                            <label htmlFor="role">Position: </label>
                            <select name="role" id={"positionInput_" + index} value={ typeof instructor['role'] === "number" ? instructor['role'] : instructor['role']['id']} onChange={e => { handleInputChange(e, index) }}>
                                <option key={"role-0"} value={'position'}>Select a Position</option>
                                {instructorRoles.map((role:intructorRoles) => {
                                    return (
                                        <option key={`role-` + role['id']} id={role['id'].toString()} value={role['id']}>{role['name']}</option>
                                    )
                                })}
                            </select>
                            <div className="instructor-schedule">
                                <label htmlFor={"timeOfDay" + index}>All Day</label>
                                <input type="radio" name={"timeOfDay_" + index} checked={true} value="AD" onChange={e => { handleInputChange(e, index) }} />
                            </div>
                            <button onClick={e => handleRemoveClick(e, index)}>-</button>
                        </div>
                    )
                })}


            </form>
            <div className="instructor-button">
                <button onClick={e => handleAddClick()} className="button primary-button">Add Instructor +</button>
                {isSaved &&
                    <div className="saving-icon">
                        <span>Saved!</span>
                    </div>
                }
                {hasError &&
                    <div className="saving-icon">
                        <span>You have missed entering information for the instructors highlighted in red</span>
                    </div>
                }
                <button onClick={e => checkRequiredFields()} className="button primary-button">Save Instructor List</button>

            </div>
        </div>)
}

export default CreateInstructorListFeature;

