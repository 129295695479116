export function timeStringToDecimal(timeString: string): number {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(':').map(Number);
  
  // Convert to decimal hours
  const decimalTime = hours + (minutes / 60);

  return decimalTime;
}


export const sortClassList = (totalRegistrations: any[], groupSize: number, registrationGroup: string) => {

  // console.log(parseInt(totalRegistrations[1].categoryTime.startTime))
  let reGroupSplitGroupbylevel: Array<object> = []
  // Loop the total Registrations Array that have split arrays for when missing in between levels 
  totalRegistrations.map((totalReg:any)=> {
    totalReg.sort((a:any, b:any) => timeStringToDecimal(a.categoryTime.startTime) - timeStringToDecimal(b.categoryTime.startTime));

    let sortedGroups: Array<object> = [];
    let currentGroup: Array<object> = [];
    let i = 0;
    let groupNum = 0
    // split arrays into groups of the number passed to the function 
    for (i; i < totalReg.length; i ++) {
       // Add the current item to the current group
        currentGroup.push(totalReg[i]);
  
  // Check if the next item exists and has the same startTime and the current group size is less than groupSize
      if (totalReg[i + 1] && totalReg[i].categoryTime.startTime === totalReg[i + 1].categoryTime.startTime && currentGroup.length < groupSize) {
        continue; // Continue adding items to the current group
      } else {
        // Finalize the current group
        sortedGroups[groupNum] = currentGroup;
        groupNum++;
        currentGroup = []; // Start a new group
      }
      // console.log(totalReg[i + 1] && (totalReg[i].categoryTime.startTime === totalReg[i + 1].categoryTime.startTime))
      // if(totalReg[i + 1] && (totalReg[i].categoryTime.startTime === totalReg[i + 1].categoryTime.startTime)){
      //   sortedGroups[groupNum] = totalReg.slice(i, i + groupSize);
      // }
      // groupNum++;
    }
    sortedGroups.forEach((group:any) => {
      return group.sort((a:any, b:any) => a.registrant.age - b.registrant.age)
    })
    // Regroup the levels that were split before to be added to the classList Array
    reGroupSplitGroupbylevel = [...reGroupSplitGroupbylevel, ...sortedGroups]
    return reGroupSplitGroupbylevel
  })
  // reGroupSplitGroupbylevel.sort((a:any,b:any) => {
  
  //   return timeStringToDecimal(a[0].categoryTime.startTime) - timeStringToDecimal(b[0].categoryTime.startTime);
  // })
  return reGroupSplitGroupbylevel;
}
