import TableHeader from "../../Components/tableHeader";

import { classProps } from "../../common/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { campId, registrationsStatus, fetchClassListContent, selectRegistrations } from "../../redux/classListSlice";
import { AppDispatch } from "../../redux/store";
import React from "react";

function ClassContent({ id, onClick }: classProps) {
    // Global State
    const registrationContent = useSelector(selectRegistrations);
    const classListStatus = useSelector(registrationsStatus);
    const selectedCampId = useSelector(campId);

    const dispatch = useDispatch<AppDispatch>();

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (classListStatus === "loading") {
            setLoading(true)
        } else {
            setLoading(false)
        }
        if (classListStatus === "idle" || selectedCampId !== id) {
            dispatch(fetchClassListContent(id))
        }

    }, [classListStatus, dispatch, id, selectedCampId])

    let TableHeaders = {
        skillCategory: { name: "Class Skill Level" },
        categoryTime: { name: "Time" },
        allocated: { name: 'Allocated' },
        registered: { name: 'Registered' },
        available: { name: 'Available' }
    };



    return (
        <td colSpan={8}>
            <button id="close-classList-button" onClick={(event) => onClick?.(event, 0)}>X</button>
            {!loading ?
                <>
                    {registrationContent && id &&
                        <table id="classContentTable">
                            <TableHeader key={id} id={id} TableHeaders={TableHeaders} />
                            <tbody>
                                {registrationContent.map((content: object, id:number) => {
                                    return (

                                        <tr key={"fr-class" + id}>
                                            {Object.entries(TableHeaders).map((tableheader:any, index:number) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {

                                                            (tableheader[0] === "skillCategory")
                                                                ?
                                                                <td data-label={tableheader[1]['name' as keyof typeof tableheader[1]]} key={tableheader[0] + "-" + content['id' as keyof typeof content]}>{content[tableheader[0] as keyof typeof content]['name']}</td>
                                                                :
                                                                (tableheader[0] === "categoryTime")
                                                                    ?
                                                                    <td data-label={tableheader[1]['name' as keyof typeof tableheader[1]]} key={tableheader[0] + "-" + content['id' as keyof typeof content]}>{content[tableheader[0] as keyof typeof content]['startTime'] + "-" + content[tableheader[0] as keyof typeof content]['endTime']}</td>
                                                                    :
                                                                    <td data-label={tableheader[1]['name' as keyof typeof tableheader[1]]}>{content[tableheader[0] as keyof typeof content]}</td>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })}

                                        </tr>

                                    )

                                })}

                            </tbody>
                        </table>
                    }
                </>
                :
                <div className="loading">
                    <img src="https://cdn.pedalheads.com/images/logos/logo-loading.png" alt="Loading" />
                </div>
            }


        </td>


    )
}

export default ClassContent;