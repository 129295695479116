import { RegistrationContent, RegistrationState } from "../../../../common/types";

export const reconfigureRegistrantObject = (registrations:RegistrationContent) => {

    let registrationsArray:RegistrationState[] = registrations.registrations;
    let newRegistrantObject = {};
    let newRegistrationObject:RegistrationContent;
    let newRegArray:RegistrationState[] = [];

    let programTypeName:string = 
    ((registrations.skillCategory.id === 3203 || registrations.skillCategory.id === 3202  || registrations.skillCategory.id === 3204) && "Trail") || registrations.programName;
    let programTypeId: number =  ((registrations.skillCategory.id === 3203 || registrations.skillCategory.id === 3202 || registrations.skillCategory.id === 3204) && 1001) || registrations.programId;
    newRegArray = registrationsArray.map((element: RegistrationState) => {
      
        let registrant = {
            id: element['registrant']['id'],
            age: element['registrant']['age'] > 11 ? Math.floor((element['registrant']['age'] / 12)) : element['registrant']['age'],
            firstName: element['registrant']['firstName'],
            lastName: element['registrant']['lastName'],
            notes: element['registrant']['notes'],
        }

        return newRegistrantObject = {
            added: element['added'],
            cancelled: element['cancelled'],
            categoryTime: element['categoryTime'] ? element['categoryTime'] : registrations['categoryTime'],
            created: element['created'],
            customer: element['customer'],
            extraCare: element['extraCare'],
            id: element['id'],
            notes: element['notes'],
            order: element['order'],
            registrant: registrant,
            skillLevel: element['skillLevel'],
            skillCategory: registrations['skillCategory'],
        }
    });

    newRegistrationObject = {
        allocated: registrations['allocated'],
        available: registrations['available'],
        categoryTime: registrations['categoryTime'],
        registered: registrations['registered'],
        registrations: newRegArray,
        skillCategory: registrations['skillCategory'],
        programName: programTypeName,
        programId: programTypeId
    }

    return newRegistrationObject;

}