import React from "react"

const ClassListValidation = ({ error, missingInstructor, saveClassLists, hasInstructorMessage, hasErrorMessage, validations, setValidations, handleStateArrayUpdate }: {error:any, missingInstructor:string[], saveClassLists:any, hasInstructorMessage:any, hasErrorMessage:any, validations:any, setValidations:any, handleStateArrayUpdate:any }) => {


    return (
        <div className="validation-component">
            {hasInstructorMessage &&
                <>
                    <h3 className="validation-error-title">Missing Instructors</h3>
                    {Object.values(missingInstructor).map((instructorMessage: string, index:number) => {
                        return (
                            <p key={index}>{instructorMessage}</p>
                        )
                    })}
                </>
            }
            {Object.entries(error).map((errorbyTime: any, index:number) => {
                let errorByClassString = errorbyTime[0].toString()
                if (Object.keys(errorbyTime[1]).length > 0) {
                    return (
                        <React.Fragment key={index}>{Object.entries(errorbyTime[1]).map((errorByProgram:any, index:number) =>{
                            return (
                                <React.Fragment key={index}>{Object.entries(errorByProgram[1]).map((errorbyClass: any, index:number) => {
                                    return(
                                    <div className="validation-component-box" key={index}>
                                        <h3 className="validation-error-title">Errors in {errorByClassString.toUpperCase()}  Class List:</h3>
                                        <h3>Please provide a reason for your Class Lists that do not meet the rule set:</h3>
                                        {errorbyClass[1].map((errormessage:any, classIndex:number) => {
                            
                                                return (
                                                    <span key={index}>
                                                        <p>{errormessage} </p>
                                                        <input
                                                            type="text"
                                                            className="validations"
                                                            name={errormessage}
                                                            id={classIndex.toString()}
                                                            value={validations && validations[errorbyTime[0]] && validations[errorbyTime[0]][errorByProgram[0]] && validations[errorbyTime[0]][errorByProgram[0]][classIndex]  ?validations[errorbyTime[0]][errorByProgram[0]][classIndex]['validation'] : ""}
                                                            onChange={e => handleStateArrayUpdate(e, validations, setValidations, errorbyTime[0], errorByProgram[0], { [classIndex]: { [index]: { ["error"]: e.target.name, ["validation"]: e.target.value } } })}
                                                        />
                                                    </span>
                                                ) 
                                
                                        })

                                        }
                                    </div>
                                    )
                                })}</React.Fragment>
                                )
                            })}</React.Fragment>
                    )
                }
            })} 
            {hasErrorMessage &&
                <button onClick={e => saveClassLists()} className="button secondary-button"> Save Validations</button>
            }
            {!hasErrorMessage && !hasInstructorMessage &&
                <p>Your Class Lists are perfect. You can now print.</p>
            }


        </div>
    )

}

export default ClassListValidation;