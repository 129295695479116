import React, { useState } from 'react';

const YearSelectDropdown = (setFilteredDates:Function, filteredDates:Object) => {
  const [isOpen, setIsOpen] = useState(false);
 

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (event:any) => {
    const { name, checked } = event.target;
    setFilteredDates({
      ...filteredDates,
      [name]: checked,
    });
  };

  return (
    <div className="year-dropdown">
      <div className="year-dropdown-toggle" onClick={handleToggleDropdown}>
       Select Year 
       <button className={isOpen ? " button  isOpen" : "button "}><img src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="10px" width="10px" alt="triangle-icon" /></button>
      </div>
      
      {isOpen && (
        <div className="dropdown-menu">
          <label>
            <input
              type="checkbox"
              name="2022"
              checked={filteredDates["2022" as keyof typeof filteredDates] ? true : false}
              onChange={handleCheckboxChange}
            />
            2022
          </label>
          <label>
            <input
              type="checkbox"
              name="2023"
              checked={filteredDates["2023" as keyof typeof filteredDates] ? true : false}
              onChange={handleCheckboxChange}
            />
            2023
          </label>
          <label>
            <input
              type="checkbox"
              name="2024"
              checked={filteredDates["2024" as keyof typeof filteredDates] ? true : false}
              onChange={handleCheckboxChange}
            />
            2024
          </label>
        </div>
      )}
    </div>
  );
};

export default YearSelectDropdown;
