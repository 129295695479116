import { RegistrationContent } from "../../../../common/types"

export const sortExtraCare = (newRegistrationObject: RegistrationContent, extraCareGroup: any) => {
    newRegistrationObject['registrations'].forEach((registration: any) => {
        if (registration['extraCare'].length > 0) {
            registration['extraCare'].map((extraCareTime:any) => {
                if (extraCareTime['startTime'] < '12:00') {
                    if (extraCareGroup['bc']&& newRegistrationObject['programName'] && extraCareGroup['bc'][newRegistrationObject['programName']!]) {
                        extraCareGroup['bc'][newRegistrationObject['programName']].push({ ...registration })
                    } else if(newRegistrationObject['programName']){
                        extraCareGroup['bc'] = {[newRegistrationObject['programName']]: [{ ...registration }]}
                    }
                } else {
                    if (extraCareGroup['ac'] && newRegistrationObject['programName'] && extraCareGroup['ac'][newRegistrationObject['programName']]) {
                        extraCareGroup['ac'][newRegistrationObject['programName']].push({ ...registration })
                    } else if(newRegistrationObject['programName']){
                        extraCareGroup['ac'] = {[newRegistrationObject['programName']]: [{ ...registration }]}
                    }
                }
            })
        }
    })
  
    return extraCareGroup;
}