import { RegistrationState, classListRegistrationState, newClassListRegistrations } from "../../../../common/types";
import { updateStudentInfo } from "./updateStudentInfo";

const CompareClassListsV2 = (newClassListRegistration:newClassListRegistrations[], classListContent:newClassListRegistrations[]) => {
    let classListRegistration: classListRegistrationState[] = [];

    if (newClassListRegistration && classListContent) {

        const checkByTimePeriod = (newRegListByTime:any, savedRegByTime:newClassListRegistrations[], timeBlock:any) => {
            // Create a new object to store the new array
            let regObj:classListRegistrationState[] = [];
            // Loop through the array of classlists for the timeblock ie AM -> bike, trail, etc
            for(let programType of  Object.keys(newRegListByTime[timeBlock])){
                // Flatten the array of classlists to be able to compare the new and old arrays
                let newArrayFlat:RegistrationState[] = newRegListByTime[timeBlock][programType]['classLists'].flat();
               
                // Create a new array to store the new classlist
                let newClassListByTime;

                if(savedRegByTime[timeBlock] &&savedRegByTime[timeBlock][programType] ){
                let oldArrayFlat:RegistrationState[] = savedRegByTime[timeBlock] && savedRegByTime[timeBlock][programType] ?  savedRegByTime[timeBlock][programType]['classLists'].flat() : [];

                // Create an object to store the new array
                let UpdatedReg:RegistrationState[] = []
                
                // Loop the new classList array flattened and update the object
                newArrayFlat.map((updatedKid:RegistrationState) => {
                    UpdatedReg[updatedKid['id']] = updatedKid
                })
                // Check for newly added students and add them to the classlist
                const newStudentArray = newArrayFlat.filter((elem:any) => {
                    return !oldArrayFlat.some((ele:any) => {
                        return ele.id === elem.id
                    });
                });

                // Update the classlist with the new students or use the only the new classlist if old students werent properly saved
                 newClassListByTime = Object.entries(updateStudentInfo(UpdatedReg, savedRegByTime[timeBlock][programType])).length > 0 ? updateStudentInfo(UpdatedReg, savedRegByTime[timeBlock][programType]) : newRegListByTime[timeBlock][programType]['classLists'];
                // Add the new students to the classlist
                if (newStudentArray.length > 0) {
                    newClassListByTime.push(...[newStudentArray]);
                }
            }else{
                newClassListByTime = newRegListByTime[timeBlock][programType]['classLists'];
            }
                // Create a new object to store the new classlist
                regObj[timeBlock] = {
                    ...(regObj[timeBlock] ?? {}),
                    [programType]: {
                        registrations: newRegListByTime[timeBlock][programType]['registrations'],
                        classLists: newClassListByTime,
                        cancelled: newRegListByTime[timeBlock][programType]['cancelled']
                    }
                };
            }
                return regObj
            
        }
        let amClasses:classListRegistrationState[] = [];
        let pmClasses:classListRegistrationState[] = [];
        let acClasses;
        let bcClasses;
        if (newClassListRegistration['am' as keyof typeof newClassListRegistration]) {
            amClasses = checkByTimePeriod(newClassListRegistration, classListContent, 'am')!
        }
        if (newClassListRegistration['pm' as keyof typeof newClassListRegistration]) {
            pmClasses = checkByTimePeriod(newClassListRegistration, classListContent, 'pm')!
        }
        if (newClassListRegistration['ac' as keyof typeof newClassListRegistration]) {
            acClasses = checkByTimePeriod(newClassListRegistration, classListContent, 'ac')
        }
        if (newClassListRegistration['bc' as keyof typeof newClassListRegistration]) {
            bcClasses = checkByTimePeriod(newClassListRegistration, classListContent, 'bc')
        }
        classListRegistration = { ...amClasses, ...pmClasses, ...acClasses, ...bcClasses };
    }

    return classListRegistration
}

export default CompareClassListsV2;