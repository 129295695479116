import { RegistrationState } from "../../../common/types";
import { classListType } from "../../../features/ClassListsFeatures/ClassListsContainer";

const grid = 8;
export const getItemStyle = (isDragging: boolean, draggableStyle: any) => (
  {

    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "15px 0px",
    margin: "0px 0px 8px",
    border: "1px solid #f2f2f2",
    background: "#ffffff",

    // styles we need to apply on draggables
    ...draggableStyle
  });




export const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "#d95c34",
  padding: grid,
  width: "95%",
});

export const getColumnBlockStyle = (isDragging: boolean, draggableStyle: any) => (
  {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "10px 10px",
    border: "1px solid #f2f2f2",
    background: "rgb(242, 242, 242)",

    // styles we need to apply on draggables
    ...draggableStyle
  });

export const getColumnStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "#ffffff",
  padding: "8px 0px",

});

// Reorder list inside same column
export const reorder = (list: RegistrationState[] | RegistrationState[][] | any[], startIndex: number, endIndex: number) => {
    // ts-ignore
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
* Moves an item from one list to another list.
*/
export const move = (source: Array<object>, destination: Array<object>, droppableSource: object, sInd: number, droppableDestination: object, dInd: number) => {

  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource["index" as keyof typeof droppableSource], 1);
  destClone.splice(droppableDestination["index" as keyof typeof droppableSource], 0, removed);
  const desCloneId: number = dInd;
  const sourceCloneId: number = sInd;
  const result: Array<object> = [];

  result[desCloneId] = destClone;
  result[sourceCloneId] = sourceClone;

  return result;
};