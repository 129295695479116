import React from "react"
import TableHeader from "../../Components/tableHeader";
import { createClassDropDown } from "../../functions/ClassListsFeatures/CreateDropDown";
import { TimeParsing } from "../../functions/DateTimeParsing";
import { SkillLevelNameParsing } from "../../functions/skillLevelNameParsing";
import { getInstructorDetails } from "../../functions/ClassListsFeatures/ClassInstructorFunctions/getAssignedInstructorDetails";
import { RegistrationState, SiteState, Sites, classListRegistrationState, classListsRegistrations, newClassListRegData, newClassListRegistrations } from "../../common/types";
import { htmlDecode } from "../../functions/htmlDecodeStrings";


export class ClassListInstructorsPrintable extends React.Component<{ classLists:classListRegistrationState, siteData:Sites, instructorArray:any, instructorState:any, currentCampId:number, startDate:string, endDate:string, cones:any }> { 


    render(): React.ReactNode {
        let TableHeaders = {
            Name: { name: "Name", colSpan: 2 },
            order: { name: "Order #" },
            skillLevel: { name: "Skill" },
            timeCategory: { name: "time" },
            attendance: { name: "" },
            monday: { name: "M" },
            tuesday: { name: "T" },
            wednesday: { name: "W" },
            thursday: { name: "Th" },
            friday: { name: "F" },
            notes: { name: "Notes", colSpan: 5 },
        };

        return (
            <div className="Print">
                <style type="text/css" media="print">{"\
                    @page {\ size: landscape; max-width:fit-content; margin: 15mm; \ }\
                "}</style>

                <div className="class-classList-level-print">
                    {Object.entries(this.props.classLists).map((classLists:[string, newClassListRegistrations], index:number) => {
                        return (
                            <React.Fragment key={index}>{Object.entries(classLists[1]).map((classList:[string, newClassListRegData], index:number) => {
                            return (
                                <div className="classlist-print-section" key={index}>
                                    {classList[1].classLists.map((classes:RegistrationState[], index:number) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="class-print-section">
                                                    <div className="top-section-print">
                                                        <h2>{createClassDropDown(this.props.classLists).map((classTime, index) => {
                                                            if (classLists[0] === classTime["key"]) {
                                                                return classTime["value"]
                                                            }

                                                        })}</h2>
                                                        <h2>{classList[0]}</h2>
                                                        <img src="https://cdn.pedalheads.com/images/logos/logo-loading.png" alt="Loading" />
                                                    </div>
                                                    <div className="print-class-details">
                                                        <p>{this.props.startDate + " - " + this.props.endDate}</p>
                                                        <p>Instructor: {this.props.instructorArray[classLists[0]] && this.props.instructorArray[classLists[0]][classList[0]] && this.props.instructorArray[classLists[0]][classList[0]][index] && getInstructorDetails(this.props.instructorArray[classLists[0]][classList[0]][index], this.props.instructorState)}</p>
                                                        <p>Cone: {this.props.cones[classLists[0]] && this.props.cones[classLists[0]][classList[0]] && this.props.cones[classLists[0]][classList[0]][index]} </p>
                                                    </div>
                                                    <table className="intructor-checkIn-printout">
                                                        <TableHeader key={0} id={0} TableHeaders={TableHeaders} />
                                                        <tbody>
                                                            {classes.map((student:RegistrationState, index:any) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <tr className={TimeParsing(student['categoryTime']!['startTime'], student['categoryTime']!['endTime']) + "-out-tr-" + classLists[0]} key={student['id'] + "in"}>
                                                                            <td rowSpan={2} colSpan={2}>{htmlDecode(student['registrant']['firstName'])} {htmlDecode(student['registrant']['lastName'])}</td>

                                                                            <td rowSpan={2}>{student['order']}</td>
                                                                            <td rowSpan={2}>{SkillLevelNameParsing(student['skillLevel']['name'])}</td>
                                                                            <td rowSpan={2}>{TimeParsing(student['categoryTime']!['startTime'], student['categoryTime']!['endTime'])}</td>
                                                                            <td>In</td>
                                                                            <td className="checkin-date-td"></td>
                                                                            <td className="checkin-date-td"></td>
                                                                            <td className="checkin-date-td"></td>
                                                                            <td className="checkin-date-td"></td>
                                                                            <td className="checkin-date-td"></td>
                                                                            <td rowSpan={2} colSpan={5}>{student['registrant']['notes'] ? student['registrant']['notes'].replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, ' ') : ""}<br />{student['notes'] ? student['notes'].replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, ' ') : ""}</td>

                                                                        </tr>
                                                                        <tr className={TimeParsing(student['categoryTime']!['startTime'], student['categoryTime']!['endTime']) + "-out-tr-" + classLists[0]} key={student['id'] + "out"}>
                                                                            <td>Out</td>
                                                                            <td className="checkout-date-td"></td>
                                                                            <td className="checkout-date-td"></td>
                                                                            <td className="checkout-date-td"></td>
                                                                            <td className="checkout-date-td"></td>
                                                                            <td className="checkout-date-td"></td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                            <tr>
                                                                <td rowSpan={2} colSpan={2}></td>
                                                                <td rowSpan={2}></td>
                                                                <td rowSpan={2}></td>
                                                                <td rowSpan={2}></td>
                                                                <td>In</td>
                                                                <td className="checkin-date-td"></td>
                                                                <td className="checkin-date-td"></td>
                                                                <td className="checkin-date-td"></td>
                                                                <td className="checkin-date-td"></td>
                                                                <td className="checkin-date-td"></td>
                                                                <td rowSpan={2} colSpan={5}></td>

                                                            </tr>
                                                            <tr >
                                                                <td>Out</td>
                                                                <td className="checkout-date-td"></td>
                                                                <td className="checkout-date-td"></td>
                                                                <td className="checkout-date-td"></td>
                                                                <td className="checkout-date-td"></td>
                                                                <td className="checkout-date-td"></td>
                                                            </tr>
                                                            <tr>
                                                                <td rowSpan={2} colSpan={2}></td>
                                                                <td rowSpan={2}></td>
                                                                <td rowSpan={2}></td>
                                                                <td rowSpan={2}></td>
                                                                <td>In</td>
                                                                <td className="checkin-date-td"></td>
                                                                <td className="checkin-date-td"></td>
                                                                <td className="checkin-date-td"></td>
                                                                <td className="checkin-date-td"></td>
                                                                <td className="checkin-date-td"></td>
                                                                <td rowSpan={2} colSpan={5}></td>

                                                            </tr>
                                                            <tr >
                                                                <td>Out</td>
                                                                <td className="checkout-date-td"></td>
                                                                <td className="checkout-date-td"></td>
                                                                <td className="checkout-date-td"></td>
                                                                <td className="checkout-date-td"></td>
                                                                <td className="checkout-date-td"></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>

                                                <div className="instructor-notes" key={"instructor-notes" + index}>
                                                    <div>
                                                        <h3>Sign-In & Out Instructions</h3>
                                                        <p><strong>In:</strong> Check off the ‘IN’ box as a camper arrives, Remind adult to have Order # at pick-up.</p>

                                                        <p><strong>Out:</strong> Check Order # from adult. Put a check mark in OUT once seen. This must be checked daily.</p>
                                                    </div>
                                                    <div>
                                                        <h3>Good-Bye Moment</h3>
                                                        <p><strong>High-Five:</strong> High-five the camper, ask them to share their best moment at pick-up.</p>
                                                        <p><strong>Any Incident:</strong>  Did anything happen that the parent should know? Share what may be discussed at home. </p>
                                                        <p><strong>Remember:</strong>  A smile and eye contact goes a long way!</p>
                                                    </div>
                                                    <div><h3>Notes/Reminders:</h3></div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })} 


                                </div>
                            )
                            })}</React.Fragment>
                        )
                    })}
                </div>

            </div >
        )
    }
}