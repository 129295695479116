import { RegistrationState } from "../../../../../common/types";

const CombineComboLevelClasses = (comboClasses: RegistrationState[]) => {
    let classByLevel: { [key: string]: RegistrationState[] } = {};;
    let skillLevel = "";
    const createGrouping = (skillLevel:string, comboStudent:RegistrationState) => {
        if (classByLevel[skillLevel as keyof typeof classByLevel]) {
            classByLevel[skillLevel as keyof typeof classByLevel].push(comboStudent)
        } else {
            classByLevel[skillLevel as keyof typeof classByLevel] = [comboStudent]
        }
    }

    comboClasses.forEach((comboStudent: RegistrationState) => {
        if (comboStudent['skillLevel']['name'] === "Level 1" ||
            comboStudent['skillLevel']['name'] === "Level 2" ||
            comboStudent['skillLevel']['name'] === "Level 3"
        ) {
            skillLevel = "Level 1-3"
            createGrouping(skillLevel, comboStudent)
        }
        else if (comboStudent['skillLevel']['name'] === "Level 4" ||
            comboStudent['skillLevel']['name'] === "Level 5" ||
            comboStudent['skillLevel']['name'] === "Level 6") {
            skillLevel = "Level 4-6"
            createGrouping(skillLevel, comboStudent)
        } else {
            skillLevel = comboStudent['skillLevel']['name']
            createGrouping(skillLevel, comboStudent)
        }
    })

    return classByLevel

}

export default CombineComboLevelClasses;
