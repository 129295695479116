import { useCallback, useRef, useState } from "react";
import ReactToPrint from "react-to-print";


export const PrintComponent = (props:any) => {


  const onBeforeGetContentResolve = useRef(Promise.resolve);

  const [loading, setLoading] = useState(false);

  const handleAfterPrint = useCallback(() => {
    console.log("`onAfterPrint` called");
    setLoading(false);
  }, []);

  const handleBeforePrint = useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const reactToPrintTrigger = useCallback(() => {
    return (<button
      className="print-report-button ltr-spn-def button primary-button"
      type="button"
    >{props.printButtonText}</button>
    )
  }, []);

  return (
    <>
      <div className="loading print-loader">
        {loading &&
          <img src="https://cdn.pedalheads.com/images/logos/logo-loading.png" alt="Loading" />
        }
        <ReactToPrint
          content={() => props.children.ref.current}
          documentTitle="pedalheads-classlists"
          onAfterPrint={handleAfterPrint}
          onBeforeGetContent={handleOnBeforeGetContent}
          onBeforePrint={handleBeforePrint}
          removeAfterPrint
          trigger={reactToPrintTrigger}
        />
      </div>
      <div className="printer-dom">
        {props.children}
      </div>

    </>
  );
}