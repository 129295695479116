import { classListRegistrationState,  newClassListRegData, newClassListRegistrations } from "../../../common/types"

export const checkInstructorsArray = (classListContent:classListRegistrationState, instructors:any, setMissingInstructor:Function) => {
    Object.entries(classListContent).map((classByTime: [string, newClassListRegistrations]) => {
       return  Object.entries(classByTime[1]).map((classByProgram: [string, newClassListRegData]) => {
        if (instructors[classByTime[0]] && instructors[classByTime[0]][classByProgram[0]] && classByProgram[1]['classLists']) {
                if (Object.keys(instructors[classByTime[0]][classByProgram[0]]).length < classByProgram[1]['classLists'].length) {
                    let missingInstructorMessage = `You are missing instructors in your ${classByTime[0].toUpperCase()} Class Lists. Please go back and review.`
                    setMissingInstructor((missingInstructor:any) => ({ ...missingInstructor, [classByTime[0]]: missingInstructorMessage }))
                } else {
                    setMissingInstructor([])
                }
            } else {
                let missingInstructorMessage = `You have not entered any instructors in your ${classByTime[0].toUpperCase()} Class Lists. Please go back and review.`
                setMissingInstructor((missingInstructor:any) => ({ ...missingInstructor, [classByTime[0]]: missingInstructorMessage }))
            }
    
       })

    })

}