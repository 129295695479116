import { RegistrationState } from "../../../../../common/types";
import { sortClassList } from "../SortClassLists";
import { SplitNoMiddleLevelClasses } from "../SplitNoMiddleLevelClasses";

export const splitNoMiddleLevelComboClasses = (levelBlock: {[key: string]: RegistrationState[]}) => {
    let groupedClasses:any[] = []
    for (let level in levelBlock) {
        if (level === 'Level 1-3') {
            let skillLevelArray = [1001, 1002, 1003]
            let splitGroup = SplitNoMiddleLevelClasses(levelBlock[level], skillLevelArray)
            let sortedClasses = sortClassList(splitGroup, 5, level)

            groupedClasses = [...sortedClasses, ...groupedClasses]

        }
        else if (level === 'Level 4-6') {
            let skillLevelArray = [2153, 2154, 2155]
            let splitGroup = SplitNoMiddleLevelClasses(levelBlock[level], skillLevelArray)
            let sortedClasses = sortClassList(splitGroup, 5, level)
            groupedClasses = [...sortedClasses, ...groupedClasses]
        } else {

            let sortedClasses = sortClassList([levelBlock[level]], 5, level)
            groupedClasses = [...sortedClasses, ...groupedClasses]
        }

    }
    return groupedClasses



}