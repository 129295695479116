import React, { useEffect, useState } from 'react';
// Import CSS
import './assets/css/global-styles.css';
import './assets/css/classList-styles.css';
import './assets/css/site-class-styles.css';
import './assets/css/print-styles.css';
// Import Redux Hooks
import { useDispatch, useSelector } from 'react-redux';
// Import Redux store
import { AppDispatch } from './redux/store';
// Import User Slice from Redux
import { checkUserContent, logUserOut, selectUsers, userDataError } from './redux/userSlice';
import { clearClassListState } from './redux/classListSlice';
// Import Components
import Navigation from './Components/Navigation';
import AlertComponent from './Components/AlertComponent';
import ModalPopup from './Components/ModalPopup';
// Import Features
import SiteContainer from './features/SiteContent/SiteContainer';
import LoginPage from './features/Login/LoginPage';
import ClassList from './features/ClassListsFeatures/ClassListsContainer';
import CampPrintedDocs from './features/SiteContent/CampPrintedDocs';
import RulesComponent from './features/SiteContent/RulesComponent';
// // React Router
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';



function App() {
  const userDetails = useSelector(selectUsers)
  const userError = useSelector(userDataError)

  const [hasStateBeenChanged, setHasStateBeenChanged] = useState(false)
  const [hasAttemptedLogin, setHasAttemptedLogin] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showRulesModal, setShowRulesModal] = useState(false)

  const dispatch = useDispatch<AppDispatch>();

  const checkUser = () => {
    if (!userDetails.userDetails || userDetails.userDetails.length < 0) {
      return false
    } else {
      return true
    }
  }

  const handleLogout = (e:React.MouseEvent) => {
    if (hasStateBeenChanged) {
      checkForChanges(e)
    } else {
      localStorage.clear()
      setHasAttemptedLogin(false)
      dispatch(logUserOut())
    }
  }
  // check for changes in camps and show alert to prevent user from navigating away
  const checkForChanges = (e: React.MouseEvent) => {
    if (hasStateBeenChanged) {
      e.preventDefault();
      setShowAlert(true)
    } else {
      dispatch(clearClassListState())
    }
  }

  useEffect(() => {
    dispatch(checkUserContent())
    if ((document.referrer === "https://login.microsoftonline.com/" || document.referrer === "https://accounts.google.com/")) {
      setHasAttemptedLogin(true)
    } else {
      setHasAttemptedLogin(false)
    }
  }, [])

  useEffect(() => {
    if (hasStateBeenChanged) {
      // Prevent going back
      window.addEventListener('popstate', (e:PopStateEvent) => {
        window.history.go(1)
        setShowAlert(true)

      });
      // Prevent reload and exit if state has changed
      window.onbeforeunload = (event) => {
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = '';
        }
        return '';
      };
    }
  })

  return (
    <div className="App">
      <header className="ph-header container">
        <div className="ph-logo">
          <Link onClick={e => checkForChanges(e)} to="/">
            <img src="https://cdn.pedalheads.com/images/logos/PH_Velo_Logo_Final_white.png" alt="Pedalheads - Velo App" />
          </Link>
        </div>
        {checkUser() &&
          <Link onClick={e => handleLogout(e)} className="logout-button" to={'/'}>Log Out</Link>
        }
      </header>
      <div className="dashboard">
        {!checkUser() ?
          <LoginPage userError={userError} hasAttemptedLogin={hasAttemptedLogin} />
          :
          <>
            <Navigation userDetails={userDetails} setShowFormModal={setShowFormModal} setShowRulesModal={setShowRulesModal} />
            <Routes>

              <Route path='*' element={<SiteContainer hasStateBeenChanged={hasStateBeenChanged} setHasStateBeenChanged={setHasStateBeenChanged} />} />
              <Route path=':siteId/:programType/classList/:classId' element={<ClassList hasStateBeenChanged={hasStateBeenChanged} setHasStateBeenChanged={setHasStateBeenChanged} />} />

            </Routes>
            {showAlert &&
              <ModalPopup>
                <AlertComponent setShowAlert={setShowAlert} setHasStateBeenChanged={setHasStateBeenChanged} />
              </ModalPopup>
            }
            {showFormModal &&
              <ModalPopup setModalVisible={setShowFormModal}>
                <CampPrintedDocs />
              </ModalPopup>
            }
            {showRulesModal &&
              <ModalPopup setModalVisible={setShowRulesModal}>
                <RulesComponent />
              </ModalPopup>
            }
          </>
        }
      </div>
    </div>
  );
}

export default App;