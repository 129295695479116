export const SkillLevelNameParsing = (levelName:any) => {
    let splitLevelName = levelName.split(" ");
    let displayName = levelName;
    if (splitLevelName[0] === "Level" || splitLevelName[0] === "Trail") {
        displayName = splitLevelName[0] + " " + splitLevelName[1]
    }else if(splitLevelName[0] === "Private"){
        displayName = levelName
    } else {
        if (levelName.split("-")[1]) {
            displayName = levelName.split("-")[0]
        } else {
            displayName = levelName
        }
    }
    return displayName
}
