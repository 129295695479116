import { Link } from "react-router-dom"

const AlertComponent = ({ setShowAlert, setHasStateBeenChanged }:{setShowAlert: Function, setHasStateBeenChanged:Function }) => {

    const returnToCurrentComponent = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        setShowAlert(false)
    }

    const returnToHomePage = () => {
        setShowAlert(false)
        setHasStateBeenChanged(false)
    }
    return (
        <div>
            <p>You have unsaved changes are you sure you want to navigate away from this page?</p>
            <div className="alert-component-buttons">
                <Link className="button primary-button" onClick={e => { returnToHomePage() }} to={'/'}>Continue to Homepage</Link>
                <Link className="button secondary-button" onClick={e => returnToCurrentComponent(e)} to={''}>Go Back and Save Changes</Link>
            </div>
        </div>
    )
}

export default AlertComponent