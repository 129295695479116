export const findMissingNumber = (arr:number[]) => {
    let missingNumber = false;

    for (let i = 0; i < arr.length - 1; i++) {
        const current = arr[i];
        const next = arr[i + 1];

        if (next - current > 1) {
            const missingRange = next - current - 1;
            for (let j = 1; j <= missingRange; j++) {
                missingNumber = true
            }
        }
    }

    return missingNumber;
}