import { toTitleCase } from "../../StringToTitleCase";

export const CreateCheckInList = (classLists:any) => {

    function removeEmptyItems(elm:any) {
        return (elm != null && elm !== false && elm !== "" && elm !== undefined);
    }
    let cleanedClassLists = classLists.filter(removeEmptyItems);
    // Combine all classes into one list
    const concatArrays = (...arr:any) => {
        const res = arr.reduce((acc:any, val:any) => {
            return acc.concat(...val);
        }, []);
        // return res;
        return res
    };
    let combineList = concatArrays(cleanedClassLists)

    let fullList = concatArrays(combineList)
    // Filter out duplicates 
    fullList = fullList.filter(removeEmptyItems);
    fullList = fullList.filter((student:any, index:any, list:any) =>
        index === list.findIndex((s:any) => (
            removeEmptyItems(student),
            s.id === student.id))
    )

    return fullList.sort((a:any, b:any) => toTitleCase(a['registrant']['firstName']) > toTitleCase(b['registrant']['firstName']) ? 1 : -1)
}