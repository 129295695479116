import { useState } from "react";
import { TimeParsing } from "../../functions/DateTimeParsing";
import { SkillLevelNameParsing } from "../../functions/skillLevelNameParsing"
import { htmlDecode } from "../../functions/htmlDecodeStrings";

const StudentInfoCard = ({ student }:any) => {
    const [showNotes, setShowNotes] = useState(false);

    const onClick = (e:any) => {
        showNotes ? setShowNotes(false) : setShowNotes(true)
    }

    return (
        <div className='student-details-block'>
            <div className="student-block-top-section">
                <p className="student-name">{htmlDecode(student[1]["registrant"]['firstName'] + " " + student[1]["registrant"]['lastName'])}</p>
                <div>
                    <span className={TimeParsing(student[1]['categoryTime']['startTime'], student[1]['categoryTime']['endTime']) + "-icon student-icon"}>{TimeParsing(student[1]['categoryTime']['startTime'], student[1]['categoryTime']['endTime'])}</span>
                </div>
            </div>
            <div className="student-block-middle-section">
                <div className="student-block-left-section">

                    <p>{SkillLevelNameParsing(student[1]['skillLevel']['name'])} </p>
                    <p>{student[1]['categoryTime']['startTime'] + "-" + student[1]['categoryTime']['endTime']}</p>
                    <p>Age: {student[1]['registrant']['age']}</p>

                </div>
                <div className="student-block-right-section">
                    {(student[1]['registrant']['notes'] || student[1]['notes']) && <button className="button primary-button show-hide-button" onClick={e => onClick(e)}>{showNotes ? "Hide Notes" : "Show Notes"}</button>}
                    <p className="EC-icon-container">{student[1]['extraCare'].length > 0 ? <span className="EC-icon">EC</span> : ""}</p>
                </div>
            </div>
    
            <div className="student-block-bottom-section">
                {student[1]['registrant']['notes'] && showNotes && <p>{student[1]['registrant']['notes'].replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, ' ')}</p>}
                {student[1]['notes'] && showNotes && <p>{student[1]['notes'].replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, ' ')}</p>}
            </div>
        </div>
       
    )
}

export default StudentInfoCard