export const errorMessageCheck = (error:string[][]) => {
    let hasErrorMessage = false;
    error && Object.values(error).map((errorbyClass: any[]) => {
        if (Object.keys(errorbyClass).length > 0) {
            hasErrorMessage = true
            return hasErrorMessage
        }
    });

    return hasErrorMessage
}