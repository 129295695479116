import { tableHeaderProps } from '../common/types';

function TableHeader({ id, TableHeaders }: tableHeaderProps) {

    return (
        <thead>
            <tr key={`header-${id}`}>
                {Object.entries(TableHeaders).map((tableheader) => {
                    return (<th colSpan={tableheader[1]['colSpan']} key={tableheader[0]}>{tableheader[1]['name']}</th>)

                })}
            </tr>
        </thead>
    )
}

export default TableHeader;