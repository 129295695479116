import React, {useState} from 'react';
export type navigationProps = {
    userDetails: userDetails,
    setShowFormModal: Function
    setShowRulesModal: Function
}

export type userDetails = {
    error?: string, 
    status:string,
    userDetails: string,
    userEmail:string,
    userFirstName:string,
    userLastName:string,
}

function Navigation({ userDetails, setShowFormModal, setShowRulesModal }: navigationProps) {
    const [showMobileDocumentsList, setShowMobileDocumentsList] = useState(false)

    let greeting = userDetails['userFirstName'] ? userDetails['userFirstName'] + " " + userDetails['userLastName'] : userDetails['userDetails']
    
    
    return (
        <nav className="primary-nav container">
            <div className="welcome">
                <p>Logged in as {greeting}</p>
                <div className="main-nav-links desktop-only">
                    <a href="https://form.jotform.com/AtlantisandPedalheads/worker-incident-report" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/ph-sitemanager-profile-Icon.png" className="ph-profile-icon" alt="profile-icon" /><span className="welcome-link-text"> Report a worker incident</span></a>
                    <a href="https://form.jotform.com/AtlantisandPedalheads/new-camp-incident-report" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/report-a-camp-incident-60.png" className="ph-profile-icon" alt="tent-icon" /><span className="welcome-link-text">  Report a camp incident</span></a>
                    <a href="https://form.jotform.com/231027335879057" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/ph-bike-icon-60.png" className="ph-profile-icon" alt="biker-icon" /><span className="welcome-link-text">  Back to Basics Report</span></a>
                    <a href="https://form.jotform.com/AtlantisandPedalheads/theme-day-2024" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/Theme-B-60.png" className="ph-profile-icon" alt="party-icon" /><span className="welcome-link-text">  Theme Day Submissions</span></a>
                    <a href="https://form.jotform.com/241514601259249" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/Theme-A-60.png" className="ph-profile-icon" alt="game-icon" /><span className="welcome-link-text">  Camp In Action (Bike)</span></a>
                    <a href="https://form.jotform.com/231484964474265" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/Generic-Checkmark-60.png" className="ph-profile-icon" alt="checkmark-icon" /><span className="welcome-link-text">  Camp In Action (Trail)</span></a>
                    <a href="#" onClick={e => setShowFormModal(true)} ><img src="https://cdn.pedalheads.com/images/web/icons/ph-sitemanager-helmet-icon.png" className="ph-profile-icon" alt="helmet-icon" /><span className="welcome-link-text">  Camp Printed Docs</span></a>
                    <a href="#" onClick={e => setShowRulesModal(true)} ><img src="https://cdn.pedalheads.com/images/web/icons/ph-sitemanager-document-icon.png" className="ph-profile-icon" alt="folder-icon" /><span className="welcome-link-text">  Class Sorting Rules</span></a>
                </div>
                <div className="mobile-only">
                <button className='button secondary-button' onClick={e => setShowMobileDocumentsList(showMobileDocumentsList ? false : true)}>See document list</button>
                <div className="main-nav-links">
                  {showMobileDocumentsList &&
                        <>
                        <a href="https://form.jotform.com/AtlantisandPedalheads/worker-incident-report" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/ph-sitemanager-profile-Icon.png" className="ph-profile-icon" alt="profile-icon" /><span className="welcome-link-text"> Report a worker incident</span></a>
                        <a href="https://form.jotform.com/AtlantisandPedalheads/new-camp-incident-report" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/report-a-camp-incident-60.png" className="ph-profile-icon" alt="tent-icon" /><span className="welcome-link-text">  Report a camp incident</span></a>
                        <a href="https://form.jotform.com/231027335879057" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/ph-bike-icon-60.png" className="ph-profile-icon" alt="biker-icon" /><span className="welcome-link-text">  Back to Basics Report</span></a>
                        <a href="https://form.jotform.com/AtlantisandPedalheads/theme-day-2024" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/Theme-B-60.png" className="ph-profile-icon" alt="party-icon" /><span className="welcome-link-text">  Theme Day Submissions</span></a>
                        <a href="https://form.jotform.com/241514601259249" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/Theme-A-60.png" className="ph-profile-icon" alt="game-icon" /><span className="welcome-link-text">  Camp In Action (Bike)</span></a>
                        <a href="https://form.jotform.com/231484964474265" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/Generic-Checkmark-60.png" className="ph-profile-icon" alt="checkmark-icon" /><span className="welcome-link-text">  Camp In Action (Trail)</span></a>
                        <a href="https://form.jotform.com/231027335879057" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/ph-bike-icon-60.png" className="ph-profile-icon" alt="profile-icon" /><span className="welcome-link-text">  Pedalheads Moments</span></a>
                <a href="#" onClick={e => setShowFormModal(true)} ><img src="https://cdn.pedalheads.com/images/web/icons/ph-sitemanager-helmet-icon.png" className="ph-profile-icon" alt="helmet-icon" /><span className="welcome-link-text">  Camp Printed Docs</span></a>
                        <a href="#" onClick={e => setShowRulesModal(true)} ><img src="https://cdn.pedalheads.com/images/web/icons/ph-sitemanager-document-icon.png" className="ph-profile-icon" alt="folder-icon" /><span className="welcome-link-text">  Class Sorting Rules</span></a>
                        </>
                    }
                    
                    </div>
                    </div>
            </div>
        </nav>

    )
}

export default Navigation;
