import { RegistrationContent, RegistrationState } from "../../../../common/types"

export const sortByTime = (newRegistrationObject: RegistrationContent, skillTimeGroup:any, startTime:string, endTime:string) => {

    if (newRegistrationObject['categoryTime']['startTime'] < startTime) {
        if (skillTimeGroup['am']) {
            skillTimeGroup['am'].push({ ...newRegistrationObject })
        } else {
            skillTimeGroup['am'] = [{ ...newRegistrationObject }]
        }

    }
    if (newRegistrationObject['categoryTime']['endTime'] > endTime) {
        if (skillTimeGroup['pm']) {
            skillTimeGroup['pm'].push({ ...newRegistrationObject })
        } else {
            skillTimeGroup['pm'] = [{ ...newRegistrationObject }]
        }
    }
    return skillTimeGroup
}