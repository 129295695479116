import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store';

import { SiteState } from '../common/types';

import axios from 'axios';


const initialState: SiteState = {
  sites: [],
  status: 'idle',
  error: ""
}

const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
})

export const fetchSiteContent = createAsyncThunk('site/fetchSiteContent', async (siteData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/sites/`)
    return response.data
  } catch (err: any) {
    console.log(err)
    return rejectWithValue(err.message)
  }
})

const siteSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSiteContent.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchSiteContent.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        state.sites = action.payload;
        if (!action.payload || action.payload.length < 0) {
          state.error = "No data is being returned from the API, please contact the system administrator";
        }
      })
      .addCase(fetchSiteContent.rejected, (state, action: any) => {
        state.status = 'failed'
        state.error = action.payload;

      })
  }
})

export const dataStatus = (state: RootState) => state.sites.status;
export const selectSite = (state: RootState) => state.sites.sites;
export const dataError = (state: RootState) => state.sites.error;
export default siteSlice.reducer