import { RegistrationContent } from "../../../../common/types";

export const sortByProgramAndLevel = (newRegistrationObject: RegistrationContent[]) => {
    let programTypeGroup: { [key: string]: {[key: string]: RegistrationContent[] }} = {};
    let skillLevelGroup:{ [key: string]: RegistrationContent[] } = {};
   const test = newRegistrationObject.map((regObj: RegistrationContent) => {

        if(regObj['programName']) {
            let skillCategoryName:string = "";
            if (regObj['skillCategory']['name'] === "Level 4" || regObj['skillCategory']['name'] === "Level 5/6") {
                skillCategoryName = "Level 4-6";
            } else {
                skillCategoryName = regObj['skillCategory']['name']
            }

            if (programTypeGroup[regObj['programName']] && programTypeGroup[regObj['programName']][skillCategoryName]) {
                // Check if the object is not already in the array
               
                    if (!programTypeGroup[regObj['programName']][skillCategoryName].some(obj => obj === regObj)) {
                        programTypeGroup[regObj['programName']][skillCategoryName].push({ ...regObj });
                    }
                
            } else {
                // Initialize the array and add the object
                programTypeGroup[regObj['programName']] = {
                    ...(programTypeGroup[regObj['programName']] || {}),
                    [skillCategoryName]: [
                      ...(programTypeGroup[regObj['programName']]?.[skillCategoryName] || []),
                      { ...regObj }
                    ]
                  };
            }
           
    }

    })

    return programTypeGroup
}