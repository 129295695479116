import {  RegistrationState,  newClassListRegistrations } from "../../../../common/types";



export const createExtraCareArray = (careObj:{[key: string]: RegistrationState[]}, classListTimeBlock:string) => {

    let GroupLevel: { [key:string]: newClassListRegistrations } = {

    }

    Object.entries(careObj).map(([programType, registrationGroups]: [string, RegistrationState[]]) => {
        

            GroupLevel = {
                ...GroupLevel,
                [classListTimeBlock]: {
                    ...(GroupLevel[classListTimeBlock]),
                    [programType]: {
                    ...(GroupLevel[classListTimeBlock]?.[programType]),
                    registrations: [] as RegistrationState[],
                    classLists: [] as RegistrationState[][],
                    cancelled: [] as RegistrationState[],
                },
            }
        }
        registrationGroups.map((registration: RegistrationState) => {
        if (Array.isArray(GroupLevel[classListTimeBlock][programType]["registrations" as keyof typeof GroupLevel.classListTimeBlock.programType])) {
            GroupLevel[classListTimeBlock][programType].registrations.push(registration);
        }
        else {
            GroupLevel[classListTimeBlock][programType].registrations  = [registration];
        }

        if (Array.isArray(GroupLevel[classListTimeBlock][programType]["classLists" as keyof typeof GroupLevel.classListTimeBlock.programType]) && GroupLevel[classListTimeBlock][programType].classLists[0]) {
            GroupLevel[classListTimeBlock][programType].classLists[0].push(registration);
        }
        else {
            GroupLevel[classListTimeBlock][programType].classLists[0] = [registration];
        }
    })
    
})

    return GroupLevel
}