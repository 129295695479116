import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'

// State Reducers
import classListReducer from './classListSlice';
import sitesReducer from './siteSlice';
import userSlice from './userSlice';
import instructorSlice from './instructorSlice';

import { combineReducers } from 'redux';




const reducers = combineReducers({
    classLists: classListReducer,
    sites: sitesReducer,
    user: userSlice,
    instructors: instructorSlice

});


const store = configureStore({
    reducer: reducers,
    // intercept and stop non-serializable values
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({

        }),



})
setupListeners(store.dispatch)

export default store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reducers>
export type AppDispatch = typeof store.dispatch