import React, { useEffect, useState, MouseEvent } from 'react';
// Import Redux Hooks
import { useDispatch, useSelector } from 'react-redux';
import { siteContainerProps } from '../../common/types';
// Import Redux Slicer for site and user content 
import { fetchSiteContent, selectSite, dataStatus, dataError } from "../../redux/siteSlice";
// Import Redux store
import { AppDispatch } from '../../redux/store';
import { Site, Sites } from '../../common/types';



// // Import Components
import SiteContent from "../SiteContent/SiteContent";
import ClassListContainer from '../ClassListsFeatures/ClassListsContainer';
import { Route, Routes } from 'react-router-dom';
import CreateInstructorListFeature from '../InstructorFeatures/CreateInstructorListFeature';
import ModalPopup from '../../Components/ModalPopup';
import { fetchInstructorsData, selectRoles, roleLoadStatus, fetchRoleData, selectInstructor } from '../../redux/instructorSlice';
import { checkUserContent, logUserOut, userDataError } from '../../redux/userSlice';
import YearSelectDropdown from './YearSelectDropdown';



function SiteContainer({ hasStateBeenChanged, setHasStateBeenChanged }: siteContainerProps) {
//   // Local State for Class Content 
  const [classVisible, setClassVisible] = useState<boolean>(false);
  const [siteVisible, setSiteVisible] = useState<boolean>(false);
  const [classId, setClassId] = useState<number>(0);
  const [siteIdState, setSiteIdState] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [filteredDates, setFilteredDates] = useState<Object>({
    '2022': false,
    '2023': false,
    '2024': true,
},);


  // Access redux stores for Site and User Content 
  const dispatch = useDispatch<AppDispatch>();
  // Global State
  const siteContent = useSelector(selectSite);
  const siteStatus = useSelector(dataStatus);
  const classDataError = useSelector(dataError);
  const instructorRoles = useSelector(selectRoles);
  const roleStatus = useSelector(roleLoadStatus);
  const instructorState = useSelector(selectInstructor);
  const userError = useSelector(userDataError);

//   // Sort by default key

  let sites = siteContent.slice().sort((a:Sites, b:Sites) => {
    return Number(b.isDefault) - Number(a.isDefault)
  })
  

  // Sort by name
  sites.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  })
//   // Get Site State if has not loaded yet
  useEffect(() => {
    if (siteStatus === "idle" || siteStatus === "failed") {
      dispatch(fetchSiteContent())

    }
    // open default camp on first load
    for (let site of sites) {
      if (site['isDefault'] === true) {
        setSiteIdState(site['siteId'])
        setSiteVisible(true)
      }
    }

  }, [siteContent, siteStatus, dispatch])

//   // Get Role State if has not loaded yet
  useEffect(() => {
    if (roleStatus === "idle" || roleStatus === "failed") {
      dispatch(fetchRoleData(siteIdState))
    }

  }, [instructorRoles, roleStatus, dispatch, siteIdState])


//   // Show Modal or show Class List feature
  const onClick = (event: MouseEvent<HTMLImageElement, MouseEventInit> | MouseEvent<HTMLButtonElement, MouseEventInit>, ClassId: number) => {
    if (ClassId && event.currentTarget.id === "class-details") {
      setClassId(ClassId)
      setClassVisible(true)
    }
    else if (ClassId && (event.currentTarget.id === "class-Lists" || event.currentTarget.id === "open-classLists-button")) {
      setClassId(ClassId)

    }
    else if (event.currentTarget.id === "back-to-site-list-button") {


    } else if (event.currentTarget.id === "close-classList-button") {
      setClassVisible(false)
    }
  }

  setTimeout(() => {
    dispatch(checkUserContent())
    if (userError !== undefined) {
      dispatch(logUserOut())
    }
  }, 300000)

  // Show instructor Modal
  const showInstructorModal = (siteId:any) => {
    dispatch(fetchInstructorsData(siteId))
    setModalVisible(true)
    dispatch(checkUserContent())
    if (userError !== undefined) {
      dispatch(logUserOut())
    }
  }

  // Toggle multi-site view
  const showclassListData = (siteId:string) => {
    setSiteIdState(siteId)
    dispatch(fetchInstructorsData(siteId))
    dispatch(checkUserContent())
    if (userError !== undefined) {
      dispatch(logUserOut())
    }
    if (siteVisible === false) {
      setSiteVisible(true)
    } else if (siteVisible === true && siteId === siteIdState) {
      setSiteVisible(false)

    }
  }

  return (
    <div className="program-location container">
      {YearSelectDropdown(setFilteredDates, filteredDates)}

      {siteContent.length > 0 ?
        <>

          {sites.map((siteDetails:Sites, index:number) => {
            // Site Name 
            const siteName = siteDetails.name.replace(/\//g, '-');
            const programType = siteDetails.program.replace(/\//g, '-');
            return (
              <React.Fragment key={index}>
                 <div className="site-container" >
                  <h2 className='site-name'>{siteName} ({programType})</h2>
                  <div className="button-container">
                  {siteVisible && siteDetails['siteId'] === siteIdState &&
                    <button className="button secondary-button" onClick={e => showInstructorModal(siteDetails['siteId'])}>Add Instructors</button>
                  }
                  <button className={siteVisible && siteIdState === siteDetails['siteId'] ? " button site-toggle-button isOpen" : "button site-toggle-button"} onClick={e => showclassListData(siteDetails['siteId'])}><img src="https://stpedalheadscdn.blob.core.windows.net/images/icons/velo-dropdown-arrow.svg" height="20px" width="20px" /></button>
                  </div>
                </div>

                {
                  modalVisible === true && siteDetails['siteId'] === siteIdState &&
                  <ModalPopup setModalVisible={setModalVisible}>
                    <CreateInstructorListFeature siteId={siteDetails['siteId']} instructorRoles={instructorRoles} instructorState={instructorState} />
                  </ModalPopup>
                }
                <Routes>

                  <Route path={`${siteIdState}/${programType}/classList/${classId}`} element={<ClassListContainer hasStateBeenChanged={hasStateBeenChanged} setHasStateBeenChanged={setHasStateBeenChanged} siteId={siteIdState} />} />

                </Routes>

                {
                  siteStatus === "fulfilled" &&
                  !classDataError &&
                  siteVisible && siteDetails['siteId'] === siteIdState &&

                  <SiteContent
                    siteId={siteIdState}
                    siteName={siteName}
                    programType={programType}
                    classVisible={classVisible}
                    classId={classId}
                    siteContent={siteDetails}
                    onClick={onClick}
                    dateFilter={filteredDates}
                  />

                }


                {
                  siteStatus !== "fulfilled" &&
                  <div className="loading">
                    <img src="https://cdn.pedalheads.com/images/logos/logo-loading.png" alt="Loading" />
                  </div>
                } 
              </React.Fragment>
            )
          })}

        </>
        :
        <>
        {siteStatus === "fulfilled" &&
          <div><p>It looks like you might not have any sites... {classDataError}</p></div>
        }
        {siteStatus === "loading" &&
          <div className="loading">
            <img src="https://cdn.pedalheads.com/images/logos/logo-loading.png" alt="Loading" />
        </div>
        }
        </>
       }
     </div> 

  )
}

export default SiteContainer;