

const ConeList = ({ index, cones, setCones, classGroupVisible, handleStateArrayUpdate, programType }:{ index:number, cones:any, setCones:Function, classGroupVisible:string, handleStateArrayUpdate:Function, programType:string }) => {

    return (
        <div className='instructor-block'>
            <input
                type="text"
                name="cone"
                placeholder={programType.includes("Swim") ? "Lane Number" : "Cone Number/ Tent"}
                onChange={e => handleStateArrayUpdate(e, cones, setCones, classGroupVisible, programType, e.target.value)}
                id={index.toString()}
                value={cones && cones[classGroupVisible] && cones[classGroupVisible][programType] && cones[classGroupVisible][programType][index] ? cones[classGroupVisible][programType][index] : ""}>
            </input>
        </div>
    )
}

export default ConeList;