import React from "react"
import TableHeader from "../../../Components/tableHeader"
import { CreateCheckInList } from "../../../functions/ClassListsFeatures/ClassListSortingFunctions/CreateCheckInList";
import { SkillLevelNameParsing } from "../../../functions/skillLevelNameParsing";
import { getInstructorDetails } from "../../../functions/ClassListsFeatures/ClassInstructorFunctions/getAssignedInstructorDetails"
import { StudentInfoSignInPrint } from "./StudentInfoSignInPrint";
import { studentInfo } from "./CreateStudentInfo";
import { Sites, classListRegistrationState, newClassListRegData, newClassListRegistrations } from "../../../common/types";


export class ClassCheckInPrintComponent extends React.Component<{ siteData:Sites, startDate:string, endDate:string, classList: classListRegistrationState, cones:any, instructorArray:any, instructorState:any }> {

    render(): React.ReactNode {

        let TableHeaders = {
            firstName: { name: "First Name", colSpan: 2 },
            lastName: { name: "Last Name", colSpan: 2 },
            cone: { name: "Cone", colSpan: 2 },
            order: { name: "Order #", colSpan: 2 },
            phone1: { name: "Phone 1", colSpan: 2 },
            phone2: { name: "Phone 2", colSpan: 2 },
            age: { name: "Age", colSpan: 1 },
            skillLevel: { name: "Skill", colSpan: 2 },
            instructor: { name: "Instructor", colSpan: 2 },
            extraCare: { name: "EC", colSpan: 2 },
            notes: { name: "Notes", colSpan: 3 },
        };

        let classBlockCancelled:any = [];
        // Create New Class List Block to add cones to each student

        const createStudentInfo = (classList:classListRegistrationState) => {
            const result:any = {};
            
            Object.entries(classList).forEach(([period, programs]:[string, newClassListRegistrations]) => {
                Object.entries(programs).forEach(([program, data]:[string, newClassListRegData]) => {
                    data['cancelled'] && data['cancelled'].length > 0 && classBlockCancelled.push(data['cancelled'].flat())
                    if (!result[program]) {
                        result[program] = {};
                    }
                    result[program][period] = studentInfo(data, this.props.cones, this.props.instructorArray, this.props.instructorState, getInstructorDetails, period, program);
                });
            });
            return result
        }
        // Create on Large Array to allow for alphabetical sorting of entire list
        let totalNumStudents = 0

        return (
            <div className="Print">
                <style type="text/css" media="print">{"\
                    @page {\ size: landscape; max-width:fit-content; \ }\
                "}</style>

                <img src="https://cdn.pedalheads.com/images/logos/pedalheads-logo-blue.png" width="250px" alt="Pedalheads" />

                <div className="class-classList-level-print class-checkin-print">
                    <div className="print-camp-details">
                        <h2>{this.props.siteData['name']}</h2>
                        <p>{this.props.startDate + " - " + this.props.endDate}</p>
                    </div>
                    {Object.entries(createStudentInfo(this.props.classList)).map((classByProgram:[string, any], index:number) => {
                        let checkInListCancelled = classBlockCancelled && classBlockCancelled.length > 0 ? CreateCheckInList(classBlockCancelled.flat()) : []
                            return (
                                <React.Fragment key={index}> <div><h3>{classByProgram[0]}</h3></div>
                                {Object.entries(classByProgram[1]).map((classByPeriod:[string, any], index:number) => {
                                    if(classByPeriod[1]){
                                        totalNumStudents += classByPeriod[1].length
                                        return(
                                            <React.Fragment key={index}> 
                                            <div><h3>{classByPeriod[0] === "am" ? "AM & AD" : classByPeriod[0].toLocaleUpperCase()} Students</h3></div>
                                            <table>
                                                <TableHeader key={0} id={0} TableHeaders={TableHeaders} />
                                                <tbody>
                                                    {classByPeriod[1].slice().sort((a:any, b:any) => {
                                                        if (a['registrant']['firstName'].toLowerCase() < b['registrant']['firstName'].toLowerCase()) {
                                                        return -1;
                                                        }
                                                        if (a['registrant']['firstName'].toLowerCase() > b['registrant']['firstName'].toLowerCase()) {
                                                        return 1;
                                                        }
                                                        return 0;
                                                    }).map((student:any, index:number) => {
                                                        return (
                                                            <React.Fragment key={index}>                                                        
                                                                <StudentInfoSignInPrint student={student} SkillLevelNameParsing={SkillLevelNameParsing} />
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    )}
                                                </tbody>
                                            </table>
                                            </React.Fragment>
                                        )
                                    }
                                })}
                                
                                    
                                <h4><strong>Total Number of students: {totalNumStudents}</strong></h4>
                                <div><h3>Cancelled</h3></div>
                                <table>
                                    <TableHeader key={0} id={0} TableHeaders={TableHeaders} />
                                    <tbody>
                                        {checkInListCancelled.map((student:any, index:number) => {
                                            return (
                                                <StudentInfoSignInPrint student={student} SkillLevelNameParsing={SkillLevelNameParsing} />
                                            )
                                        })}
                                    </tbody>
                                </table>
                        </React.Fragment>
                        )
                    })}
                </div>
            </div>
        )
    }
}