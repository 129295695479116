import React, { useEffect, useState } from 'react';

// // Components
import DragDropOuterComponent from '../../Components/DragDropOuterComponentV2';

// // types
import {   newClassListRegData, newClassListRegistrations } from '../../common/types';

// // Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateClassListState, selectClassLists } from "../../redux/classListSlice";
import { AppDispatch } from '../../redux/store';
import { classListsRegistrations } from '../../common/types';
// // Functions
import { ClassListErrorHandling } from '../../functions/ClassListsFeatures/ClassListValidationFunctions/classListErrorHandling';

import { CancelledRegList } from './CancelledRegList';
import { DateParsing } from '../../functions/DateTimeParsing';

function ClassList({ handleStateArrayUpdate, setClassLists, classLists, classGroupVisible, error, setError, instructors, setInstructors, setCones, cones, setHasStateBeenChanged, setClassListValid, updatedTimeStamp }: {handleStateArrayUpdate:Function, setClassLists:Function, classLists:newClassListRegistrations | undefined,  classGroupVisible:string, error:string[][], setError:Function, instructors:object, setInstructors:Function, setCones:Function, cones:object, setHasStateBeenChanged:Function, setClassListValid:Function, updatedTimeStamp:string}) {
	// redux State
	const classListContent: classListsRegistrations = useSelector(selectClassLists);
	const [classListStateUpdated, setClassListStateUpdated] = useState(false);
	let newClassListContent = classListContent
	const dispatch = useDispatch<AppDispatch>();

	 // Push updated state to a new variable and then allow global state to update
	const classListUpdateFunction = (classList:newClassListRegData['classLists'], key:string) =>{
		if(key){
			setClassLists((classLists:newClassListRegistrations) => ({...classLists, [key]:{ ...classLists[key as keyof typeof classLists], classLists: classList}}))
		}

		let groupContent = newClassListContent[classGroupVisible as keyof typeof newClassListContent];
		if (typeof groupContent === 'object' && groupContent !== null) {
			newClassListContent = {
				...newClassListContent,
				[classGroupVisible]:
				{
					...groupContent,
					[key]: {
						...groupContent[key as keyof typeof groupContent],
						'classLists': classList
					}
				}
			}

			dispatch(updateClassListState(newClassListContent))
		}
	}

	useEffect(() => {
		if (classGroupVisible === "am" || classGroupVisible === "pm") {

			ClassListErrorHandling(classLists, setError, error, classGroupVisible, setClassListValid)
		}
	}, [classLists])

	return (
		<div className='class-classList-container'>
		 {updatedTimeStamp.length > 0 && <p>Last Saved Class Plan: {DateParsing(updatedTimeStamp)}</p>} 
			<p>Click and drag the selected student to the desired column. On this page you can allocate students to a class by class level and time category.<br /> If you don't see your saved class plan try refreshing your browser.</p>

			{Object.entries(classLists!).map(([key, classGroup]: [string, newClassListRegData]) => {
					return (	
						<React.Fragment key={key}>
						<DragDropOuterComponent
							handleStateArrayUpdate={handleStateArrayUpdate}
							classLists={classGroup.classLists}
							setClassLists={classListUpdateFunction}
							programType={key}
							classGroupVisible={classGroupVisible}
							instructors={instructors}
							setInstructors={setInstructors}
							cones={cones}
							setCones={setCones}
							error={error}
							setHasStateBeenChanged={setHasStateBeenChanged}
							updatedTimeStamp={updatedTimeStamp}
						/>
						<CancelledRegList cancelledClassLists={classGroup.cancelled} /> 
						</React.Fragment>
					);
			})}

		</div>
	)
}

export default ClassList;