import { errors } from '../../functions/ClassListsFeatures/ClassListValidationFunctions/classListErrorContent'
const RulesComponent = () => {
    // Get all Bike Full Length Classes and Group Together
    let bikeRegularLevelObj = errors.map((errorByLevel) => {
        return Object.entries(errorByLevel).filter(levels => {
            if (levels[0] === "1001" || levels[0] === "1002" || levels[0] === "1003" || levels[0] === "2153" || levels[0] === "2154" || levels[0] === "2155") {
                return true
            } else {
                return false
            }
        }).map((levels) => {
            return levels
        })
    })
    // Get all Bike Half Length Classes and Group Together
    let bikeShortClassObj = errors.map((errorByLevel) => {
        return Object.entries(errorByLevel).filter(levels => {
            if (levels[0] === "1" || levels[0] === "2" || levels[0] === "2021" || levels[0] === "2134") {
                return true
            } else {
                return false
            }
        }).map((levels) => {
            return levels
        })
    })

    // Get Trail 1 & 2  Classes and Group Together
    let TrailLevelObj12 = errors.map((errorByLevel) => {
        return Object.entries(errorByLevel).filter(levels => {
            if (levels[0] === "2137" || levels[0] === "2138" || levels[0] === "2273" || levels[0] === "2274") {
                return true
            } else {
                return false
            }
        }).map((levels) => {
            return levels
        })
    })

    // Get Trail 3  Classes and Group Together (because map puts them in order of id and not of class type)
    let TrailLevelObj3 = errors.map((errorByLevel) => {
        return Object.entries(errorByLevel).filter(levels => {
            if (levels[0] === "2013" || levels[0] === "2275") {
                return true
            } else {
                return false
            }
        }).map((levels) => {
            return levels
        })
    })



    return (
        <div className="class-rule-display">
            <h2>Rules For Sorting Class Lists</h2>
            <div className="class-rule-nav">
                <a className="button primary-button" href="#bike">Bike</a>
                <a className="button primary-button" href="#trail">Trail</a>
                <a className="button secondary-button" href="#instructor">Instructor</a>
            </div>
            <div className="class-rule-header-container">
                <h3 id="bike">Bike Classes</h3>
            </div>

            {
                bikeShortClassObj.map((errorByLevel) => {
                    return errorByLevel.map((levels) => {
                        return (
                            <div>
                                <h4>{levels[1]['level']}</h4>
                                <p>{levels[1]['rules']}</p>
                            </div>
                        )

                    })

                })
            }
            {
                bikeRegularLevelObj.map((errorByLevel) => {
                    return errorByLevel.map((levels) => {
                        return (
                            <div>
                                <h4>{levels[1]['level']}</h4>
                                <p>{levels[1]['rules']}</p>
                            </div>
                        )

                    })

                })
            }
            <div className="class-rule-header-container">
                <h3 id="trail">Trail Classes</h3>
            </div>
            {
                TrailLevelObj12.map((errorByLevel) => {
                    return errorByLevel.map((levels) => {
                        return (
                            <div>
                                <h4>{levels[1]['level']}</h4>
                                <p>{levels[1]['rules']}</p>
                            </div>
                        )

                    })

                })
            }
            {
                TrailLevelObj3.map((errorByLevel) => {
                    return errorByLevel.map((levels) => {
                        return (
                            <div>
                                <h4>{levels[1]['level']}</h4>
                                <p>{levels[1]['rules']}</p>
                            </div>
                        )

                    })

                })
            }
            <div className="class-rule-header-container">
                <h3 id="instructor">Instructor Rules</h3>
            </div>
            <ul className="instructor-rules">
                <li>When there are 9 or more total <strong>classes(groups)</strong> at one time in the AM or PM at camp, the Camp Supervisor is not assigned a class as an Instructor.</li>
                <li>When there are 8 or less <strong>classes(groups)</strong> at one time in the AM or PM at camp, the Camp Supervisor should be treated as an Instructor.</li>
                <li>When there are 15 or less <strong>kids</strong> in the overall camp, both the Camp Supervisor and Camp Manager are treated as Instructors.</li>
            </ul>
        </div >
    )
}

export default RulesComponent;