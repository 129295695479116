import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { RootState } from './store';


import axios from 'axios';


const initialState = {
    instructorStatus: 'idle',
    instructorState: [],
    instructorError: "",
    instructorAPIMessage: "",
    rolesStatus: 'idle',
    roles: [],
    rolesError: ""
}

const axiosInstance = axios.create({
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
})

// Fetch Instructors Data
export const fetchInstructorsData = createAsyncThunk('instructors/fetchInstructorsData', async (instructordata: any, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/instructors/${instructordata}`)
        return response.data
    } catch (err: any) {
        console.log(err)
        return rejectWithValue(err.message)
    }
})

// Fetch Instructors Role Data
export const fetchRoleData = createAsyncThunk('instructors/fetchRoleData', async (instructordata: any, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/roles/`)
        return response.data
    } catch (err: any) {
        console.log(err)
        return rejectWithValue(err.message)
    }
})

// Put instructors 
export const saveInstructorState = createAsyncThunk('site/saveInstructorContent', async (instructordata: any, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}/instructors/${instructordata['siteId']}`, instructordata['instructorList']
        )
        return response.data
    } catch (err: any) {
        return rejectWithValue(err.message)
    }
})

const siteSlice = createSlice({
    name: 'instructors',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchInstructorsData.pending, (state, action) => {
                state.instructorStatus = 'loading'
            })
            .addCase(fetchInstructorsData.fulfilled, (state, action) => {
                state.instructorStatus = 'fulfilled';
                if (action.payload.message) {
                    state.instructorAPIMessage = action.payload.message
                    state.instructorState = []
                } else {
                    state.instructorState = action.payload;
                }
                if (!action.payload || action.payload.length < 0) {
                    state.instructorStatus = "No data is being returned from the API, please contact the system administrator";
                }
            })
            .addCase(fetchInstructorsData.rejected, (state, action: any) => {
                state.instructorStatus = 'failed'
                state.instructorStatus = action.payload;

            })
            .addCase(fetchRoleData.pending, (state, action) => {
                state.rolesStatus = 'loading'
            })
            .addCase(fetchRoleData.fulfilled, (state, action) => {
                console.log(action)
                state.rolesStatus = 'fulfilled'
                state.roles = action.payload;

                if (!action.payload || action.payload.length < 0 || action.payload[1] > 0) {
                    state.rolesStatus = "No data is being returned from the API, please contact the system administrator";
                }
            })
            .addCase(fetchRoleData.rejected, (state, action: any) => {
                state.rolesStatus = 'failed'
                state.rolesStatus = action.payload;

            })
    }
})

// Export Instructor Data
export const instructorLoadStatus = (state: RootState) => state.instructors.instructorStatus;
export const selectInstructor = (state: RootState) => state.instructors.instructorState;
export const instructorError = (state: RootState) => state.instructors.instructorError;
export const instructorAPIMessage = (state: RootState) => state.instructors.instructorAPIMessage;

// Export Instructor Role Data 
export const selectRoles = (state: RootState) => state.instructors.roles;
export const roleLoadStatus = (state: RootState) => state.instructors.rolesStatus;
export const roleError = (state: RootState) => state.instructors.rolesError;

export default siteSlice.reducer