export const studentInfo = (classListContent:any, cones:any, instructorArray:any, instructorState:any, getInstructorDetails:any, period:string, program:string) => {

    const studentBlockInfo = (student:any, index:number) => {
        return ({
            added: student['added'],
            cancelled: student['cancelled'],
            categoryTime: student['categoryTime'],
            created: student['created'],
            customer: student['customer'],
            extraCare: student['extraCare'],
            id: student['id'],
            notes: student['notes'],
            order: student['order'],
            registrant: student['registrant'],
            skillLevel: student['skillLevel'],
            cone: (cones && cones[period] && cones[period][program] && cones[period][program][index] ? cones[period][program][index]: "N/A"),
            instructor: (instructorArray && instructorArray[period] && instructorArray[period][program] && instructorArray[period][program][index] ?  getInstructorDetails(instructorArray[period][program][index], instructorState) : "" ),

        })
    }
    // Removed AD from PM list
    if(period === "am") {
        return classListContent['classLists'].map((classList:any, index:number) => {
            return (
                classList.map((student:any) => {
                    return studentBlockInfo(student, index)
                })
            )
        }).flat();
    }else if(period === "pm"){
        return classListContent['classLists'].map((classList:any, index:number) => {
            return (
                classList.map((student:any) => {
                    if (student['categoryTime']['startTime'] > "11:59") {
                        return studentBlockInfo(student, index)
                    }
                })
            ).filter((value:any) => value !== undefined)
        }).flat();
    }
}
