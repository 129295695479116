import { newClassListRegistrations } from '../../../common/types';
import { errors } from './classListErrorContent'
import { RegistrationState } from '../../../common/types'; // Add the missing import statement

// Define the structure of the errorArrayState interface
export interface errorArrayState {
    [ProgramType: string]: {
        [ClassId: string]: string[] | undefined
    }
}

export const ClassListErrorHandling = (classLists:newClassListRegistrations | undefined, setError:Function, error:string[][], timeCategory:string, setClassListValid:Function) => {
     // Initialize an empty ErrorArray
    let ErrorArray: any[] = [];
    // Get the error list
    const errorList:any = errors
    // Initialize an array to store skill level combinations
    let skillLevelCombinations = [];

    if(classLists !== undefined) {    
           // Iterate over each programType and classListObj
    for (let [programType, classListObj] of Object.entries(classLists)) {
        // Map skill level combinations for each class
        skillLevelCombinations = classListObj.classLists.map((classes: RegistrationState[]) => { 
            return classes.map((student: RegistrationState) => {
                return student['skillLevel']['id']
            })
        });

  

         // Function to add mix class error to ErrorArray
        const addMixClassErrorArray = (skillLevelCombinations:any, index:number) => {

            let errorMessage = errorList[0][skillLevelCombinations[0]]["mixedClassError"] + (index+1)
               
                if (!ErrorArray[programType as keyof typeof ErrorArray]) {
                    ErrorArray = {
                        ...ErrorArray,
                        [programType]: {
                           
                        }
                    }
                }
                    if(!ErrorArray[programType as keyof typeof ErrorArray][index]) {
                        ErrorArray = {
                            ...ErrorArray,
                            [programType]: {
                                ...ErrorArray[programType as keyof typeof ErrorArray],
                                [index]: 
                                []
                            }
                        }
                    }

                    if(ErrorArray[programType as keyof typeof ErrorArray][index] && !ErrorArray[programType as keyof typeof ErrorArray][index].includes(errorMessage)) {
                        ErrorArray[programType as keyof typeof ErrorArray][index]?.push(errorMessage);
                    }
       
        }

       // Function to add overage error to ErrorArray  
        const addOverageErrorArray = (skillLevelCombinations:any, index:number) => {
            let errorMessage = errorList[0][skillLevelCombinations[0]]["classOverage"] + (index+1)
    
            if (!ErrorArray[programType as keyof typeof ErrorArray]) {
                ErrorArray = {
                    ...ErrorArray,
                    [programType]: {
                       
                    }
                }
            }
                if(!ErrorArray[programType as keyof typeof ErrorArray][index]) {
                    ErrorArray = {
                        ...ErrorArray,
                        [programType]: {
                            ...ErrorArray[programType as keyof typeof ErrorArray],
                            [index]: 
                            []
                        }
                    }
                }

                if(ErrorArray[programType as keyof typeof ErrorArray][index] && !ErrorArray[programType as keyof typeof ErrorArray][index].includes(errorMessage)) {
                    ErrorArray[programType as keyof typeof ErrorArray][index]?.push(errorMessage);
                }
        }
        const nonCombinableFound = (array:number[], combinable:number[]) => {
           return array.some(num => !combinable.includes(num));
        } 

        
        for( let skillLevels of skillLevelCombinations) {
            // No mix with Balanced Bikers
            if (skillLevels.includes(2134) && !allAreEqual(skillLevels)) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
        
            if (skillLevels.includes(2134) && !allAreEqual(skillLevels)) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))

            }
            // Max 4 kids
            if (skillLevels.includes(2134) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // No mix with Trikes and Trainers
            if (skillLevels.includes(2) && !allAreEqual(skillLevels)) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // Max 4 kids
            if (skillLevels.includes(2) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // No mix Riding Rookies 
            if (skillLevels.includes(1) && !allAreEqual(skillLevels)) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // Max 6 kids
            if (skillLevels.includes(1) && skillLevels.length > 6) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // No mix with Private Classes
            if (skillLevels.includes(2021) && !allAreEqual(skillLevels)) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }

            // No mix Levels 4-6 with Level 1
            if (skillLevels.includes(1001) && (skillLevels.includes(2153) || skillLevels.includes(2154) || skillLevels.includes(2155))) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))

            }
            // Max 6 kids
            if (skillLevels.includes(1001) && skillLevels.length > 6) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // No mix Levels 4-6 with Level 2
            if (skillLevels.includes(1002) && (skillLevels.includes(2153) || skillLevels.includes(2154) || skillLevels.includes(2155))) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))

            }
            // Max 6 kids
            if (skillLevels.includes(1002) && skillLevels.length > 6) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // No mix Levels 4-6 with Level 3
            if (skillLevels.includes(1003) && (skillLevels.includes(2153) || skillLevels.includes(2154) || skillLevels.includes(2155))) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // Max 6 kids
            if (skillLevels.includes(1003) && skillLevels.length > 6) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // Level 4
            // Max 5 kids
            if (skillLevels.includes(2153) && skillLevels.length > 5) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // Level 5
            // Max 5 kids
            if (skillLevels.includes(2154) && skillLevels.length > 5) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // Level 6
            // Max 5 kids
            if (skillLevels.includes(2155) && skillLevels.length > 5) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
// ***************************Swim ****************************************// 
            // skill level Goldfish
            // Max 5 kids
            if (skillLevels.includes(2222) && skillLevels.length > 5) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2222) && nonCombinableFound(skillLevels, [2222, 2223, 2224])) {

                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // skill level Jellyfish
            // Max 5 kids
            if (skillLevels.includes(2223) && skillLevels.length > 5) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2223) &&  nonCombinableFound(skillLevels, [2222, 2223, 2224])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))

            }
            // skill level Seahorse
            // Max 5 kids
            if (skillLevels.includes(2224) && skillLevels.length > 5) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2224) &&  nonCombinableFound(skillLevels, [2222, 2223, 2224])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))

            }
            // skill level Octopus
            // Max 4 kids
            if (skillLevels.includes(2225) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2225) && !allAreEqual(skillLevels)) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // Octopus Bike Combo
            if (skillLevels.includes(2242) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2242) && !allAreEqual(skillLevels)) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // Octopus Trail Combo
            if (skillLevels.includes(2256) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2256) && !allAreEqual(skillLevels)) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // Octopus Soccer Combo
            if (skillLevels.includes(2276) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2276) && !allAreEqual(skillLevels)) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // skill level Seahorse+
            // Max 3 kids
            if (skillLevels.includes(2226) && skillLevels.length > 3) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2226) && !allAreEqual(skillLevels)) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // skill level Orca
            // Max 4 kids
            if (skillLevels.includes(2227) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            } 
            if (skillLevels.includes(2227) &&  nonCombinableFound(skillLevels, [2227, 2228])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))

            }
            // Orca Bike Combo
            if (skillLevels.includes(2245) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            } 
            if (skillLevels.includes(2227) &&  nonCombinableFound(skillLevels, [2227, 2228])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))

            }
            // skill level Crab
            // Max 4 kids   
            if (skillLevels.includes(2228) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2228) &&  nonCombinableFound(skillLevels, [2227, 2228])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))

            }
            // skill level Sea Lion
            // Max 4 kids
            if (skillLevels.includes(2229) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2229) &&  nonCombinableFound(skillLevels, [2229, 2230])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))

            }
            // skill level Narwhal
            //Max 4 kids
            if (skillLevels.includes(2230) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2229) &&  nonCombinableFound(skillLevels, [2229, 2230])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // skill level Swimmer 1
            // Max 4 kids
            if (skillLevels.includes(2231) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2231) && !allAreEqual(skillLevels)) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // skill level Swimmer 2
            // Max 4 kids
            if (skillLevels.includes(2232) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2232) && !allAreEqual(skillLevels)) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // skill level Swimmer 3
            // Max 4 kids
            if (skillLevels.includes(2233) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2233) &&  nonCombinableFound(skillLevels, [2233, 2234])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // skill level Swimmer 4
            // Max 4 kids
            if (skillLevels.includes(2234) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2234) &&  nonCombinableFound(skillLevels, [2233, 2234])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // skill level Swimmer 5
            // Max 4 kids
            if (skillLevels.includes(2235) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2235) &&  nonCombinableFound(skillLevels, [2235, 2236])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // skill level Swimmer 6
            // Max 4 kids
            if (skillLevels.includes(2236) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2236) &&  nonCombinableFound(skillLevels, [2235, 2236])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // skill level Swimmer 7
            // Max 4 kids
            if (skillLevels.includes(2237) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2237) &&  nonCombinableFound(skillLevels, [2237, 2238,2239])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // skill level Swimmer 8
            // Max 4 kids
            if (skillLevels.includes(2238) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2238) &&  nonCombinableFound(skillLevels, [2237, 2238,2239])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            // skill level Swimmer 9
            // Max 4 kids
            if (skillLevels.includes(2239) && skillLevels.length > 4) {
                addOverageErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }
            if (skillLevels.includes(2239) &&  nonCombinableFound(skillLevels, [2237, 2238,2239])) {
                addMixClassErrorArray(skillLevels, skillLevelCombinations.indexOf(skillLevels))
            }

        }
    }
}

// Set classListValid based on ErrorArray length
    if (ErrorArray.length > 0) {
        setClassListValid(false)
    } else {
        setClassListValid(true)
    }

    setError({ ...error, [timeCategory]:  ErrorArray })
}

// // Check if all the category ID's in the array are the same
function allAreEqual(array:any) {
    const result = array.every((element:any) => {
        if (element === array[0]) {
            return true;
        }
    });

    return result;
}
