
import { sortClassList } from "./SortClassLists";
import { SplitNoMiddleLevelClasses } from "./SplitNoMiddleLevelClasses"
import { CombineAllRegByTimeLevel } from "./CombineAllRegByTimeLevel"
import CombineComboLevelClasses from "./ComboCampsSorting/combineComboLevelClasses";
import { splitNoMiddleLevelComboClasses } from "./ComboCampsSorting/splitNoMiddleLevelComboClasses";
import { RegistrationContent, RegistrationState, newClassListRegData } from "../../../../common/types";
import { groupNumbersByCat } from "../../ClassListValidationFunctions/classListErrorContent";
export const createClassListRegCancelledArrays = (registrations: { [key: string]: {[key: string]: RegistrationContent[] }}, classListTimeBlock: string) => {

    let classLists = [];
    let GroupLevel: { [x: string]: {[x: string]:newClassListRegData }} = {};

    Object.entries(registrations).map((registrationGroups: [string, { [key: string]: RegistrationContent[]; }], index:number) => {
        
        GroupLevel = {
            ...GroupLevel,
            [classListTimeBlock]: {
                ...(GroupLevel[classListTimeBlock] || {}),
                [registrationGroups[0]]: {
                    ...(GroupLevel[classListTimeBlock]?.[registrationGroups[0]] || {}),
                    registrations: [],
                    classLists: [],
                    cancelled: [],
                },
            }
        }

        for (let registrationGroup in registrationGroups[1]) {

            // Map Registrations Objects to pull out Registration List 
            let regByGrouping = registrationGroups[1][registrationGroup].map((element: RegistrationContent) => {
                return element['registrations'].map((element: RegistrationState) => {
                    return !element['cancelled'] ? element: []
                }).flat()
            })

            // Map Registrations Objects to pull out Cancelled Registration List
            let cancelledReg = registrationGroups[1][registrationGroup].map((element: RegistrationContent) => {
                return element['registrations'].map((element: RegistrationState) => {
                    return element['cancelled'] ? element: []
                }).flat()
            }).flat()

            let regGroupedByRuleSet:any = []
    
            // Combine All Registrations by Start Time and Level / Level Category 
            let combinedReg:RegistrationState[] = CombineAllRegByTimeLevel(regByGrouping)
            // Create an array of all skillLevels in the grouping to split when there is no middle level
            let skillLevelArray:number[] = []
            combinedReg.forEach((regObj: RegistrationState) => {
                if (!skillLevelArray.includes(regObj['skillLevel']['id'])) {
                    skillLevelArray.push(regObj['skillLevel']['id'])
                }
            })

            if (registrationGroups[0] === "Trail - Combo Camps" || registrationGroups[0] === "Bike - Combo Camps" || registrationGroups[0] === "Soccer - Combo Camps" || registrationGroups[0] === "Bike/Swim Combo" || registrationGroups[0] === "Trail/Swim Combo" || registrationGroups[0] === "Soccer/Swim Combo") {
                let combinedByLevel = CombineComboLevelClasses(combinedReg)
                classLists = splitNoMiddleLevelComboClasses(combinedByLevel)
            } else {
                if(registrationGroups[0].includes("Swim")){
                        regGroupedByRuleSet = [combinedReg]
                }else{
                    if (skillLevelArray.length > 1) {
                        regGroupedByRuleSet = SplitNoMiddleLevelClasses(combinedReg, skillLevelArray)
                    }
                    else {
                        regGroupedByRuleSet = [combinedReg]
                    }
                }
                //create Class Lists sorted by rule number 5 for classes and 8 for extra care 
                if (combinedReg && (classListTimeBlock === 'ac' || classListTimeBlock === 'bc')) {
                    classLists = sortClassList(regGroupedByRuleSet, 8, registrationGroup)
                } else {
                    let skillCatId = regGroupedByRuleSet && regGroupedByRuleSet[0] && regGroupedByRuleSet[0][0] && regGroupedByRuleSet[0][0]['skillCategory'] && regGroupedByRuleSet[0][0]['skillCategory']['id'].toString()
                    let groupingNum = groupNumbersByCat[skillCatId as keyof typeof groupNumbersByCat] && groupNumbersByCat[skillCatId as keyof typeof groupNumbersByCat]['maxCampers'] ? groupNumbersByCat[skillCatId as keyof typeof groupNumbersByCat]['maxCampers'] : 5
                    classLists = sortClassList(regGroupedByRuleSet, groupingNum, registrationGroup)
                }
            }
          classLists.sort((a, b) => {
            let sortA = a[0]?.skillLevel?.sort ?? 0;  // Using nullish coalescing operator and optional chaining
            let sortB = b[0]?.skillLevel?.sort ?? 0;
            return sortA - sortB;
        });
            // Push both classlists, registration objects and cancelled lists together into one object 
            if(GroupLevel[classListTimeBlock as keyof typeof GroupLevel] &&  GroupLevel[classListTimeBlock as keyof typeof GroupLevel][registrationGroups[0]]){
                    GroupLevel[classListTimeBlock as keyof typeof GroupLevel][registrationGroups[0]]["registrations"].push(...combinedReg);
                
                    GroupLevel[classListTimeBlock as keyof typeof GroupLevel][registrationGroups[0]]['classLists'].push(...classLists);
                
                    GroupLevel[classListTimeBlock as keyof typeof GroupLevel][registrationGroups[0]]['cancelled'].push(...cancelledReg);
            
               
            }
    }
    if(registrationGroups[0].includes("Swim") && Array.isArray(GroupLevel[classListTimeBlock as keyof typeof GroupLevel][registrationGroups[0]]['classLists'])){
        GroupLevel[classListTimeBlock as keyof typeof GroupLevel][registrationGroups[0]]['classLists'].sort((a:any[], b:any[]) => { 
            if (a[0]['categoryTime']['startTime'] < b[0]['categoryTime']['startTime']) {
                return -1;
              }
              if (a[0]['categoryTime']['startTime'] > b[0]['categoryTime']['startTime']) {
                return 1;
              }
              return 0;})
    }
    
    })  
        // Return object of all three arrays 
    return GroupLevel
}
