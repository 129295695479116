import TableHeader from "../../Components/tableHeader";
import { classListsRegistrations } from "../../common/types";

export const CancelledRegList = (classLists:classListsRegistrations) => {

    let TableHeaders = {
        studentName: { name: "Student Name" },
        skillLevel: { name: "Level" },
    };

    let allCancelled:any[] = Object.values(classLists).map((classList:any) => {
        return classList
    }).flat()

    return (
        <>
            <h2>Cancelled Students</h2>
            {allCancelled.length > 0 ?
                <table>
                    <TableHeader id={0} TableHeaders={TableHeaders} />
                    <tbody>
                        {allCancelled.map((cancelledStudent, index) => {
                            return (

                                <tr key={index}>
                                    <td>{cancelledStudent['registrant']['firstName']} {cancelledStudent['registrant']['lastName']}</td>
                                    <td>{cancelledStudent['skillLevel']['name']}</td>

                                </tr>
                            )

                        })}
                    </tbody>
                </table>
                :
                <p>There are no cancelled registrations for this time period.</p>
            }
        </>
    )
}

