import { CategoryTimeState, RegistrantState, RegistrationContent, RegistrationState, skillCategoryState, skillLevelState } from "../../../../../common/types";

export const createComboCampRegObject = (registrations:RegistrationContent, config: string) => {
    let registrationsArray: RegistrationState[] = registrations.registrations;
    let newRegistrantObject = {};
    let newRegistrationObject: RegistrationContent;
    let newRegArray:RegistrationState[] = [];
    let programName:string = "";
    let programId:number = 0;
    let newCategoryTime:CategoryTimeState = {
        id: 0,
        name: "",
        startTime: "",
        endTime: ""
    };
    let skillCategoryObj:skillCategoryState;

    skillCategoryObj = {
        id: 0,
        name: "Combo Camp",
    }
    if(config === "new") {
        if (registrations['categoryTime']['id'] === 4741) {
            newCategoryTime = {
                id: 4741,
                name: "Combo Camp - Bike PM",
                startTime: "12:30",
                endTime: "15:30"
            }
        }
        if (registrations['categoryTime']['id'] === 4740) {
            newCategoryTime = {
                id: 4740,
                name: "Combo Camp - Bike AM",
                startTime: "09:00",
                endTime: "12:30"
            }
        }
        if (registrations['categoryTime']['id'] === 4609) {
            newCategoryTime = {
                id: 4609,
                name: "Combo Camp - Bike AM",
                startTime: "09:00",
                endTime: "13:00"
            }
        }
        if (registrations['categoryTime']['id'] === 4610) {
            newCategoryTime = {
                id: 4610,
                name: "Combo Camp - Bike PM",
                startTime: "13:00",
                endTime: "16:00"
            }
        }
        if (registrations['categoryTime']['id'] === 4348) {
            newCategoryTime = {
                id: 4348,
                name: "JER Combo Camp - Bike PM",
                startTime: "13:00",
                endTime: "16:00"
            }
        }
        if (registrations['categoryTime']['id'] === 4742) {
            newCategoryTime = {
                id: 4742,
                name: "Combo Camp PR - Trail AM",
                startTime: "09:00",
                endTime: "12:30"
            }
        }
        if (registrations['categoryTime']['id'] === 4743) {
            newCategoryTime = {
                id: 4743,
                name: "Combo Camp PR - Trail PM",
                startTime: "12:00",
                endTime: "15:30"
            }
        }
        if (registrations['categoryTime']['id'] === 4813) {
            newCategoryTime = {
                id: 4813,
                name: "Combo Camp PR - Trail AM",
                startTime: "09:00",
                endTime: "12:30"
            }
        }
        if(registrations['programId'] === 1002) {
            programName = "Bike - Combo Camps"
            programId = 1
        }
        if(registrations['programId'] === 1008) {
            programName = "Trail - Combo Camps"
            programId = 1001
        }
        if(registrations['programId'] === 1011) {
            programName = "Soccer - Combo Camps"
            programId = 1001
        }

        newRegArray = registrationsArray.map((element: RegistrationState) => {
            let skillLevelObj:skillLevelState;

            let registrant = {
                id: element['registrant']['id'],
                age: element['registrant']['age'] > 11 ? Math.floor((element['registrant']['age'] / 12)) : element['registrant']['age'],
                firstName: element['registrant']['firstName'],
                lastName: element['registrant']['lastName'],
                notes: element['registrant']['notes'],
            }
    
            if (element['comboSkillLevel']) {
                if (element['comboSkillLevel'] === 1001) {
                    skillLevelObj = {
                        id: 1001,
                        name: "Level 1",
                        sort: 203
                    }
                }
                else if (element['comboSkillLevel'] === 1002) {
                    skillLevelObj = {
                        id: 1002,
                        name: "Level 2",
                        sort: 204
                    }
                }
                else if (element['comboSkillLevel'] === 1003) {
                    skillLevelObj = {
                        id: 1003,
                        name: "Level 3",
                        sort: 205
                    }
                }
                else if (element['comboSkillLevel'] === 2153) {
    
                    skillLevelObj = {
                        id: 2153,
                        name: "Level 4",
                        sort: 206
                    }
                }
                else if (element['comboSkillLevel'] === 2154) {
                    skillLevelObj = {
                        id: 2154,
                        name: "Level 5",
                        sort: 207
                    }
                }
                else if (element['comboSkillLevel'] === 2155) {
                    skillLevelObj = {
                        id: 2155,
                        name: "Level 6",
                        sort: 208
                    }
                } else if (element['comboSkillLevel'] === 3105) {
                    skillLevelObj = {
                        id: 3105,
                        name: "Trail 1",
                        sort: 108
                    }
    
                } else if (element['comboSkillLevel'] === 2137) {
                    skillLevelObj = {
                        id: 2137,
                        name: "Trail 1",
                        sort: 108
                    }
    
                } else if (element['comboSkillLevel'] === 2138) {
                    skillLevelObj = {
                        id: 2138,
                        name: "Trail 2",
                        sort: 109
                    }
                } else if (element['comboSkillLevel'] === 2013) {
                    skillLevelObj = {
                        id: 2013,
                        name: "Trail 3",
                        sort: 109
                    }
                } else if (element['comboSkillLevel'] === 2273) {
                    skillLevelObj = {
                        id: 2273,
                        name: "Trail 1",
                        sort: 108
                    }
    
                } else if (element['comboSkillLevel'] === 2274) {
                    skillLevelObj = {
                        id: 2274,
                        name: "Trail 2",
                        sort: 109
                    }
                } else if (element['comboSkillLevel'] === 2275) {
                    skillLevelObj = {
                        id: 2275,
                        name: "Trail 3",
                        sort: 110
                    }
                } else {
                    skillLevelObj = {
                        id: 0,
                        name: "No Level",
                        sort: 0
                    }
                }
            }
            else {
                skillLevelObj = {
                    id: 0,
                    name: "No Level",
                    sort: 0
                }
            }
            return newRegistrantObject = {
                added: element['added'],
                cancelled: element['cancelled'],
                categoryTime: newCategoryTime,
                created: element['created'],
                customer: element['customer'],
                extraCare: element['extraCare'],
                id: element['id'],
                notes: element['notes'],
                order: element['order'],
                registrant: registrant,
                skillLevel: skillLevelObj,
                comboSkillLevel: element['comboSkillLevel'],
                skillCategory: skillCategoryObj,
            }
        });
    }

    if(config === "old") {
        if (registrations['categoryTime']['id'] === 4741) {
            newCategoryTime = {
                id: 4741,
                name: "Combo Camp - Swim AM",
                startTime: "9:00",
                endTime: "12:30"
            }
        }
        if (registrations['categoryTime']['id'] === 4740) {
            newCategoryTime = {
                id: 4740,
                name: "Combo Camp - Swim PM",
                startTime: "12:30",
                endTime: "15:30"
            }
        }
        if (registrations['categoryTime']['id'] === 4609) {
            newCategoryTime = {
                id: 4609,
                name: "Combo Camp - Swim PM",
                startTime: "13:00",
                endTime: "16:00"
            }
        }
        if (registrations['categoryTime']['id'] === 4610) {
            newCategoryTime = {
                id: 4610,
                name: "Combo Camp - Swim AM",
                startTime: "09:00",
                endTime: "13:00"
               
            }
        }
        if (registrations['categoryTime']['id'] === 4348) {
            newCategoryTime = {
                id: 4348,
                name: "Combo Camp PR - Swim AM",
                startTime: "09:00",
                endTime: "12:30"
            }
        }
        if (registrations['categoryTime']['id'] === 4742) {
            newCategoryTime = {
                id: 4742,
                name: "JER Combo Camp - Swim PM",
                startTime: "13:00",
                endTime: "16:00"
                
            }
        }
        if (registrations['categoryTime']['id'] === 4743) {
            newCategoryTime = {
                id: 4743,
                name: "Combo Camp PR - Swim AM",
                startTime: "09:00",
                endTime: "12:30"
            }
        }
        if (registrations['categoryTime']['id'] === 4813) {
            newCategoryTime = {
                id: 4813,
                name: "Combo Camp PR - Swim PM",
                startTime: "12:00",
                endTime: "15:30"
            }
          
        }

            programName = "Swim - Combo Camps"
            programId = registrations['programId']
            newRegArray = registrationsArray.map((element: RegistrationState) => {

                let registrant = {
                    id: element['registrant']['id'],
                    age: element['registrant']['age'] > 11 ? Math.floor((element['registrant']['age'] / 12)) : element['registrant']['age'],
                    firstName: element['registrant']['firstName'],
                    lastName: element['registrant']['lastName'],
                    notes: element['registrant']['notes'],
                }
            return newRegistrantObject = {
                added: element['added'],
                cancelled: element['cancelled'],
                categoryTime: newCategoryTime,
                created: element['created'],
                customer: element['customer'],
                extraCare: element['extraCare'],
                id: element['id'],
                notes: element['notes'],
                order: element['order'],
                registrant: registrant,
                skillLevel: element['skillLevel'],
                comboSkillLevel: element['comboSkillLevel'],
                skillCategory: registrations['skillCategory']
            }
            })
        
    }

    newRegistrationObject = {
        allocated: registrations['allocated'],
        available: registrations['available'],
        categoryTime: newCategoryTime,
        registered: registrations['registered'],
        registrations: newRegArray.length > 0 ? newRegArray : registrations['registrations'],
        registrationsCancelled: registrations['registrationsCancelled'],
        skillCategory: skillCategoryObj,
        programId: programId,
        programName: programName
    }

    return newRegistrationObject;
}