import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import axios from 'axios';
import { userState } from '../common/types';


const initialState: userState = {
  status: 'idle',
  error: "",
  userEmail: "",
  userFirstName: "",
  userLastName: "",
  userDetails: ""
}

const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
})

export const checkUserContent = createAsyncThunk('class/fetchUserContent', async (userData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/user/getcurrentuser`)
    return response.data
  } catch (err: any) {
    console.log(err)
    return rejectWithValue(err.message)
  }

})

export const logUserOut = createAsyncThunk('class/fetchUserContent', async (userData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/user/signout`)
    return response.data
  } catch (err: any) {
    console.log(err)
    return rejectWithValue(err.message)
  }

})

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(checkUserContent.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(checkUserContent.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.userEmail = action.payload.email;
        state.userFirstName = action.payload.firstName;
        state.userLastName = action.payload.lastName;
        state.userDetails = action.payload.userDetails;
        state.error = action.payload.message;
        if (!action.payload || action.payload.length < 1) {
          state.error = "No data is being returned from the API, please contact the system administrator";
        }
      })
      .addCase(checkUserContent.rejected, (state, action: any) => {
        state.status = 'failed'
        if (action.payload === "Network Error") {
          state.error = "Unable to Authenticate User"
        } else {
          state.error = action.payload;
        }
      })
  }
})
export const dataStatus = (state: RootState) => state.user.status;
export const userEmail = (state: RootState) => state.user.userEmail;
export const selectUsers = (state: RootState) => state.user;
export const userDataError = (state: RootState) => state.user.error;

export default UserSlice.reducer
