import React, { useRef } from "react";

// Import Types
import { Camps, PrintProps, classListRegistrationState } from "../../common/types";

//  Import Features
import { ClassListPrintable } from "./ClassListPrintable";
import { ClassCheckInPrintComponent } from "./ClassSignInPrint/ClassCheckInPrintComponent";
import { ClassListInstructorsPrintable } from "./ClassListInstructorPrintComponent";
import { PrintComponent } from "../../Components/PrintComponent";

// Import Redux
import { campId, } from "../../redux/classListSlice";
import { selectSite } from '../../redux/siteSlice'
import { useSelector } from "react-redux";

//  Import Functions
import { DateParsing } from "../../functions/DateTimeParsing";




export const PrintFeature = ({ currentSiteData, classListContent, instructors, instructorState, cones }: PrintProps) => {

    const siteData = useSelector(selectSite)
    const currentCampId = useSelector(campId)

    let currentCamp:Camps = {} as Camps;
    currentSiteData && currentSiteData['camps'].map(camp => {
        if (camp['id'] === currentCampId) {
            currentCamp = camp
        }
    })
    const startDate = currentCamp['startDate'] && DateParsing(currentCamp['startDate'])
    const endDate = currentCamp['endDate'] && DateParsing(currentCamp['endDate'])

    const classListRef = useRef(null);
    const CheckInRef = useRef(null);
    const instructorClassListRef = useRef(null);

    
    const splitPrograms = (classListRegs:classListRegistrationState) => {
        const classListByProgram:classListRegistrationState[] = [];
        // get the program keys from the am and pm objects
        const amProgramKeys = classListRegs.am ? Object.keys(classListRegs.am) : "";
        const pmProgramKeys = classListRegs.pm ? Object.keys(classListRegs.pm) : "";
        const bcProgramKeys = classListRegs.bc ? Object.keys(classListRegs.bc) : "";
        const acProgramKeys = classListRegs.ac ? Object.keys(classListRegs.ac) : "";
        // Combine and deduplicate keys
        const allKeys = Array.from(new Set([...amProgramKeys, ...pmProgramKeys, ...bcProgramKeys, ...acProgramKeys])); 
      
        allKeys.forEach((key: string, index:number) => {
            classListByProgram[index] = { am:{}, pm:{}, ac:{}, bc:{} };
          if(classListRegs.am && classListRegs.am[key]) {
            classListByProgram[index].am = { [key]: classListRegs.am[key] }
          }
          if(classListRegs.pm && classListRegs.pm[key]){
            classListByProgram[index].pm = { [key]: classListRegs.pm[key] }
          }
          if(classListRegs.bc && classListRegs.bc[key]){
            classListByProgram[index].bc = { [key]: classListRegs.bc[key] }
          }
          if(classListRegs.ac && classListRegs.ac[key]){
            classListByProgram[index].ac = { [key]: classListRegs.ac[key] }
          }
        });
      
        return classListByProgram;
      }
        
      let classObjByProgram:classListRegistrationState[] = splitPrograms(classListContent);
      //   Create refs for each program
      const instructorClassListRefs = classObjByProgram.map(() => React.createRef<ClassListInstructorsPrintable>());
      const classListRefs = classObjByProgram.map(() => React.createRef<ClassListPrintable>());
      const CheckInRefs = classObjByProgram.map(() => React.createRef<ClassCheckInPrintComponent>());

    return (
        <>
        {Object.keys(classObjByProgram).includes("am") ?
        <>
            <PrintComponent printButtonText={"Preview Instructor Sign-In/Out"} >
                <ClassListInstructorsPrintable
                    ref={instructorClassListRef}
                    classLists={classListContent}
                    siteData={currentSiteData}
                    currentCampId={currentCampId}
                    startDate={startDate}
                    endDate={endDate}
                    instructorArray={instructors}
                    instructorState={instructorState}
                    cones={cones}
                />
            </PrintComponent>
            <PrintComponent printButtonText={"Preview Class List"} >
                <ClassListPrintable
                    ref={classListRef}
                    classLists={classListContent}
                    siteData={currentSiteData}
                    currentCampId={currentCampId}
                    startDate={startDate}
                    endDate={endDate}
                    instructorArray={instructors}
                    instructorState={instructorState}
                    cones={cones}
                />
            </PrintComponent>
            <PrintComponent printButtonText={"Preview Sign-In List"}>
                <ClassCheckInPrintComponent
                    classList={classListContent}
                    ref={CheckInRef}
                    siteData={currentSiteData}
                    startDate={startDate}
                    endDate={endDate}
                    instructorArray={instructors}
                    instructorState={instructorState}
                    cones={cones}
                />
            </PrintComponent>
            </>
            :
            <div className="multi-program-print-container">
            {classObjByProgram.map((classObj:classListRegistrationState, index:number) => {
                const amKeys = classObj.am ? Object.keys(classObj.am) :Object.keys(classObj.pm);
               return( 
                    <div key={index}>
                        <h2>{amKeys[0]}</h2>
                        <PrintComponent key={`sign-in-${amKeys[0]}`} printButtonText={`Preview  Instructor Sign-In/Out`} >
                        <ClassListInstructorsPrintable
                            ref={instructorClassListRefs[index]}
                            classLists={classObj}
                            siteData={currentSiteData}
                            currentCampId={currentCampId}
                            startDate={startDate}
                            endDate={endDate}
                            instructorArray={instructors}
                            instructorState={instructorState}
                            cones={cones}
                        />
                    </PrintComponent>
                    <PrintComponent  key={`class-list-${amKeys[0]}`} printButtonText={`Preview  Class List`} >
                        <ClassListPrintable
                            ref={classListRefs[index]}
                            classLists={classObj}
                            siteData={currentSiteData}
                            currentCampId={currentCampId}
                            startDate={startDate}
                            endDate={endDate}
                            instructorArray={instructors}
                            instructorState={instructorState}
                            cones={cones}
                        />
                    </PrintComponent>
                    <PrintComponent  key={`sign-in-list-${amKeys[0]}`} printButtonText={`Preview  Sign-In List`}>
                        <ClassCheckInPrintComponent
                            classList={classObj}
                            ref={CheckInRefs[index]}
                            siteData={currentSiteData}
                            startDate={startDate}
                            endDate={endDate}
                            instructorArray={instructors}
                            instructorState={instructorState}
                            cones={cones}
                        />
                    </PrintComponent>
                </div>
            )
        })}
        </div>
        }
    </>
    );
}