import { RegistrationState } from "../../../../common/types";

function orderRegistrations (a: RegistrationState, b: RegistrationState) {
    if(a.categoryTime && b.categoryTime){
        if (parseInt(a.categoryTime.startTime) > parseInt(b.categoryTime.startTime)) return 1;
        if (parseInt(a.categoryTime.startTime) < parseInt(b.categoryTime.startTime)) return -1;
    }
    if (a.skillLevel.sort > b.skillLevel.sort) return 1;
    if (a.skillLevel.sort < b.skillLevel.sort) return -1;
    return 0;
}
export const CombineAllRegByTimeLevel = (regByGrouping:RegistrationState[][]) => {
    
    let totalRegistrations: RegistrationState[] = [];
    // Loop through the classLevels, get the registration arrays and combine them together
    for (let regStudentsList of regByGrouping) {
        totalRegistrations = [...totalRegistrations, ...regStudentsList]
    }
    // Resort by Level to make sure Level 1 am and ad are together before am level 2 
    return totalRegistrations.sort(orderRegistrations)
}

export const swimCombineAllRegByTimeLevel = (regByGrouping:RegistrationState[][]) => {
    let totalRegistrations: RegistrationState[] = [];
    // Loop through the classLevels, get the registration arrays and combine them together
    for (let regStudentsList of regByGrouping) {
        totalRegistrations = [...totalRegistrations, ...regStudentsList]
    }
    // Resort by Level to make sure Level 1 am and ad are together before am level 2 
    return totalRegistrations.sort((a:RegistrationState, b:RegistrationState) => (a.skillLevel.sort > b.skillLevel.sort) ? 1 : ((b.skillLevel.sort > a.skillLevel.sort) ? -1 : 0))
}