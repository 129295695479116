import { useDispatch } from 'react-redux';
// Import Redux store
import { AppDispatch } from '../../redux/store';
// Import User Slice from Redux
import { logUserOut } from '../../redux/userSlice';
//  Import types
import { loginProps } from '../../common/types';

const LoginPage = ({ userError, hasAttemptedLogin }: loginProps) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="login container">
      <div className="login-container">
        <div className="demo-login">
          <h3>Login to Velo</h3>
          {hasAttemptedLogin && userError && <p>{userError}. Please contact your administrator.</p>}
        </div>
        <div className="third-party">
          <div className="auth-buttons">
            <a href={`${process.env.REACT_APP_API_URL}/user/google-signin?post_login_redirect_uri=${process.env.REACT_APP_BASEURL}`} className="auth-button google" >
              <img className="auth-logo" alt="" src="https://cdn.pedalheads.com/images/web/icons/social-google-logo.svg" />
              Sign in with Google
            </a>

            <a href={`${process.env.REACT_APP_API_URL}/user/ms-signin?post_login_redirect_uri=${process.env.REACT_APP_BASEURL}`} className="auth-button microsoft">
              <img className="auth-logo" alt="" src="https://cdn.pedalheads.com/images/web/icons/social-microsoft-logo-white.png" />
              Sign in with Microsoft
            </a>
          </div>
        </div>
      </div>
    </div >
  );
}

export default LoginPage
