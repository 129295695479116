import { StudentSignINState } from "../../../common/types";
import { toTitleCase } from "../../../functions/StringToTitleCase";
import { htmlDecode } from "../../../functions/htmlDecodeStrings";

export type signInStudentInfo = {
    student: StudentSignINState,
    SkillLevelNameParsing: Function
}

export const StudentInfoSignInPrint = ({ student, SkillLevelNameParsing }: signInStudentInfo) => {
    return (

        <tr key={student['id']}>
            <td colSpan={2}>{htmlDecode(toTitleCase(student['registrant']['firstName']))}</td>
            <td colSpan={2}>{htmlDecode(toTitleCase(student['registrant']['lastName']))}</td>
            <td colSpan={2}>{student['cone']}</td>
            <td colSpan={2}>{student['order']}</td>
            <td colSpan={2}>{student['customer']['phone1']}</td>
            <td colSpan={2}>{student['customer']['phone2']}</td>
            <td colSpan={1}>{student['registrant']['age']}</td>
            <td colSpan={2}>{SkillLevelNameParsing(student['skillLevel']['name'])}</td>
            <td colSpan={2}>{student['instructor']}</td>
            <td colSpan={2}>{student['extraCare'][0] && parseInt(student['extraCare'][0]['startTime']) < 12 && "AM"} {(student['extraCare'][0] && parseInt(student['extraCare'][0]['startTime']) > 15 && "PM")} {(student['extraCare'][1] && parseInt(student['extraCare'][1]['startTime']) > 15 && "PM")}</td>
            <td colSpan={3}></td>
        </tr>
    )
}