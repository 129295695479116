import { RegistrantState, RegistrationState } from "../../../../common/types";
import { findMissingNumber } from "./CheckForMissingNumberInArray";

export const SplitNoMiddleLevelClasses = (combinedReg: RegistrationState[], skillLevelArray:number[]) => {
    let totalSplitRegistrations: Array<Array<object>> = [];

    const missingMiddleLevel = findMissingNumber(skillLevelArray.sort((a, b) => a - b));
    let firstLevel:number;
    let secondLevel:number;
    let thirdLevel:number;

    if (missingMiddleLevel) {
        firstLevel = skillLevelArray[0];

        thirdLevel = skillLevelArray[1];
    } else {
        firstLevel = skillLevelArray[0];
        secondLevel = skillLevelArray[1];
        thirdLevel = skillLevelArray[2];
    }

    // Get all three levels from obj
    let firstLevelStudentList = combinedReg.filter(student => student['skillLevel']["id"] === firstLevel)
    let secondLevelStudentList = combinedReg.filter(student => student['skillLevel']["id"] === secondLevel)
    let thirdLevelStudentList = combinedReg.filter(student => student['skillLevel']["id"] === thirdLevel)
    // Check and see if the middle level has registrations or not
    if (secondLevelStudentList.length > 0) {
        // push whole obj if all three levels are present
        totalSplitRegistrations = [combinedReg]
    } else {
        // Split first and third levels into separate categories if no middle level
        totalSplitRegistrations = [firstLevelStudentList, thirdLevelStudentList]
    }
    return totalSplitRegistrations
}