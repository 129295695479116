import React from "react";
import { Link } from "react-router-dom";

// Import Components
import TableHeader from '../../Components/tableHeader';
import TableFooter from '../../Components/tableFooter';
// Import Features
import ClassContent from '../ClassContent/ClassContent';
import { Camps, Sites } from "../../common/types";

export interface siteProps {
    siteId: string,
    siteName: string,
    programType: string,
    classVisible: boolean,
    classId: number,
    siteContent: Sites,
    onClick: (event: React.MouseEvent<HTMLImageElement, MouseEventInit> | React.MouseEvent<HTMLButtonElement, MouseEventInit>, classId: number) => void;
    dateFilter: Object,
}


function SiteContent({ siteId, programType, classVisible, classId, onClick, siteContent, dateFilter }: siteProps) {
    let siteContentCamps = siteContent.camps;
    let Allocated = siteContent.allocated;
    let Registered = siteContent.registered;
    let Percentage = siteContent.percentage;

    let TableHeaders = {
        startDate: { name: 'Start Date' },
        endDate: { name: 'End Date' },
        allocated: { name: 'Allocated' },
        registered: { name: 'Registered' },
        percentage: { name: 'Fill %' },
        classDetails: { name: "Class Details" },
        classLists: { name: 'Class Lists' },
    };
     // Filter camps by year 
  const filterByYear = () => {
    let filteredCamps = siteContentCamps.filter((camp:any) => {
       let campYear = camp['endDate' as keyof typeof camp].split('-')[0]
        return dateFilter[campYear as keyof typeof dateFilter] 
      })
    return filteredCamps
  }

    return (
        <table id="siteListContent">
            <TableHeader id={0} TableHeaders={TableHeaders} />
            <tbody>
                {Object.values(filterByYear()).map((content: Camps, index: number) => {
                    return (
                        <React.Fragment key={"fr-" + content['id' as keyof typeof content]}>

                            <tr key={index}>
                                {Object.entries(TableHeaders).slice(0, 5).map((tableheader, index) => {
                                    let newTime: string = "";
                                    let time: Date;
                                    if (tableheader[0] === "startDate" || tableheader[0] === "endDate") {
                                        // set date string value to variable
                                        let date: string = content[tableheader[0] as keyof typeof content].toString();
                                        // add Time so it doesnt get converted to UTC
                                        time = new Date(date + "T00:00:00");
                                        // Format as requested 
                                        newTime = time.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" });
                                    }
                                    return (
                                        <React.Fragment key={"tb-" + index}>
                                            {
                                                (tableheader[0] === "startDate"
                                                    || tableheader[0] === "endDate")
                                                    ?
                                                    <td data-label={tableheader[1]['name' as keyof typeof tableheader[1]]} key={tableheader[0] + "-" + content['id' as keyof typeof content]}>{newTime}</td>
                                                    :
                                                    <td data-label={tableheader[1]['name' as keyof typeof tableheader[1]]}  key={tableheader[0] + "-" + content['id' as keyof typeof content]}>{content[tableheader[0] as keyof typeof content]}</td>
                                            }
                                        </React.Fragment>
                                    )
                                })}
                                <td data-label={"Class Details"} key={'class-details' + content['id' as keyof typeof content]}>
                                    <img id="class-details" className="icon actionable details" onClick={(event) => onClick?.(event, content.id)} src="https://cdn.pedalheads.com/images/web/icons/eye-regular.svg" alt="See Details" />
                                </td>

                                {content['registered'] > 0 &&
                                    <td data-label={"Class Plan"} key={'class-list' + content['id' as keyof typeof content]}>
                                        <Link to={`${siteId}/${programType}/classList/${content['id' as keyof typeof content]}`}>
                                            <button id="open-lists-button" className="button primary-button" onClick={(event) => onClick?.(event, content.id)}>Open</button>
                                        </Link>
                                    </td>
                                }
                            </tr>
                            {
                                classVisible && classId === content.id &&
                                <tr key={"class-" + content.id} id={`classes-content-${content.id}`} className="nested-table">
                                    <ClassContent id={content.id} onClick={onClick} />
                                </tr>
                            }
                        </React.Fragment>
                    )

                })}

                <TableFooter
                    Allocated={Allocated}
                    Percentage={Percentage}
                    Registered={Registered} />

            </tbody>
        </table>

    )
}

export default SiteContent;