import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

import { classListState, RegistrationContent } from '../common/types'
import axios from 'axios';

const initialState: classListState = {
  registrationStatus: 'idle',
  registrationError: "",
  campId: 0,
  classListsRegistrations: {},
  firstClassListsRegistrations: [],
  instructorArray: [],
  conesArray: [],
  errorValidations: {},
  errorArray: [],
  oldRegistrations: [],
  checkInList: [],
  auxInstructorArray:{},
  rearRideramArray: [],
  rearRiderpmArray:  [],
  classListsStatus: 'idle',
  classListsError: "",
  updatedTime: ""
}

const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
})


export const fetchClassListContent = createAsyncThunk('class/fetchClassListContent', async (classId: number, { rejectWithValue }) => {

  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/ClassList/${classId}`)
    return response.data
  } catch (err: any) {
    console.log(err)
    return rejectWithValue(err.message)
  }
})

export const saveClassListContent = createAsyncThunk('class/saveClassListContent', async (state: any, { rejectWithValue }) => {
  let saveState = {
    jsonDoc: state['jsonDoc'],
    isValid: state['isValid'],
    jsonAlerts: state['jsonAlerts']
  }

  try {
    const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}/Classplan/${state['campId']}`, saveState)
    return response.data
  } catch (err: any) {
    console.log(err)
    return rejectWithValue(err.message)
  }
})

export const pullLastSavedClassContent = createAsyncThunk('class/pullLastSavedClassContent', async (classId: any, { rejectWithValue }) => {

  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/Classplan/${classId}`)
    return response.data
  } catch (err: any) {
    console.log(err)
    return rejectWithValue(err.message)
  }
})


const ClassListSlice = createSlice({
  name: 'classLists',
  initialState,
  reducers: {
    createClassListState(state, action: PayloadAction<any>) {
      state.classListsRegistrations = action.payload
    },
    updateClassListState(state, action: PayloadAction<any>) {
      state.classListsRegistrations = action.payload
    },
    createInstructorState(state, action: PayloadAction<any>) {
      state.instructorArray = action.payload
    },
    createConeState(state, action: PayloadAction<any>) {
      state.conesArray = action.payload
    },
    createCheckInState(state, action: PayloadAction<any>) {
      state.checkInList = action.payload
    },
    clearClassListState(state) {
      state.registrationStatus = 'idle';
      state.registrationError = "";
      state.campId = 0;
      state.classListsRegistrations = {};
      state.firstClassListsRegistrations = [];
      state.instructorArray = [];
      state.auxInstructorArray = {};
      state.rearRideramArray = [];
      state.rearRiderpmArray = [];
      state.conesArray = [];
      state.errorValidations = {};
      state.errorArray = [];
      state.oldRegistrations = [];
      state.checkInList = [];
      state.classListsStatus = 'idle';
      state.classListsError = "";
      state.updatedTime = ""
    
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClassListContent.pending, (state, action) => {
        state.registrationStatus = 'loading'
      })
      .addCase(fetchClassListContent.fulfilled, (state, action) => {
        state.registrationStatus = 'fulfilled';
        if (action.payload.allocations) {
          // recreate allocation state to push programId and programName to each allocation
          const createAllocationState = [
            ...action.payload.allocations
          ]
          createAllocationState.forEach((allocation: RegistrationContent) => {
            allocation.programId = action.payload.programId;
            allocation.programName = action.payload.programName;
          })
          
          state.oldRegistrations = createAllocationState;
        }
        state.campId = action.meta.arg;
        if (!action.payload || action.payload.length < 1) {
          state.registrationError = "No data is being returned from the API, please contact the system administrator";
        }
      })
      .addCase(fetchClassListContent.rejected, (state, action: any) => {
        state.registrationStatus = 'failed'
        state.registrationError = action.payload;
      })

      .addCase(pullLastSavedClassContent.pending, (state, action) => {
        state.classListsStatus = 'loading'
      })
      .addCase(pullLastSavedClassContent.fulfilled, (state, action) => {
        state.classListsStatus = 'fulfilled';
        if (action.payload.jsonDoc) {
          const savedClassData = JSON.parse(action.payload.jsonDoc)
          state.firstClassListsRegistrations = savedClassData['classList']
          state.conesArray = savedClassData['cones']
          state.instructorArray = savedClassData['instructors']
          state.errorArray = savedClassData['errors']
          state.auxInstructorArray = savedClassData['auxInstructorState']
          state.rearRideramArray = savedClassData['rearRideram']
          state.rearRiderpmArray = savedClassData['rearRiderpm']
          state.classListsError = "Saved State Loaded"
          state.campId = action.meta.arg
          state.updatedTime = action.payload.updated
        } else {
          state.classListsError = "No Saved State"
        }
        if (!action.payload || action.payload.length < 1) {
          state.classListsError = "No data is being returned from the API, please contact the system administrator";
        }
      })
      .addCase(pullLastSavedClassContent.rejected, (state, action: any) => {
        state.classListsStatus = 'failed'
        state.classListsError = action.payload;
      })

  }
})
export const registrationsStatus = (state: RootState) => state.classLists.registrationStatus;
export const registrationsError = (state: RootState) => state.classLists.registrationError;
export const selectRegistrations = (state: RootState) => state.classLists.oldRegistrations;
export const firstSelectClassLists = (state: RootState) => state.classLists.firstClassListsRegistrations;

export const classListData = (state: RootState) => state.classLists;
export const selectClassLists = (state: RootState) => state.classLists.classListsRegistrations;
export const instructorArray = (state: RootState) => state.classLists.instructorArray;
export const conesArray = (state: RootState) => state.classLists.conesArray;
export const errorArray = (state: RootState) => state.classLists.errorArray;
export const auxInstructorArray = (state: RootState) => state.classLists.auxInstructorArray;
export const rearRideramArray = (state: RootState) => state.classLists.rearRideramArray;
export const rearRiderpmArray = (state: RootState) => state.classLists.rearRiderpmArray;
export const validationErrorObj = (state: RootState) => state.classLists.errorValidations;
export const classListsStatus = (state: RootState) => state.classLists.classListsStatus;
export const classListsError = (state: RootState) => state.classLists.classListsError;
export const updatedTime = (state: RootState) => state.classLists.updatedTime;
export const selectCheckInList = (state: RootState) => state.classLists.checkInList;
export const campId = (state: RootState) => state.classLists.campId;


export const { updateClassListState, createClassListState, createInstructorState, createConeState, createCheckInState, clearClassListState } = ClassListSlice.actions
export default ClassListSlice.reducer
