type ModalProps = {
    setModalVisible?: Function;
} & Record<string, any>; // Allow any other props

const ModalPopup = (props:ModalProps) => {
    const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const target = event.target as HTMLElement
        if (props.setModalVisible && (target.className === "modal-component" || target.className === "close-modal")) {
            props.setModalVisible(false)
        }
    }



    return (
        <div className="modal-component" onClick={event => onClick(event)}>
            <div className="modal-content" >
                {props.setModalVisible && <div className="close-modal" onClick={e => onClick(e)}>X</div>}
                <div className="modal-children-components">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default ModalPopup;