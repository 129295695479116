import { RegistrationContent, RegistrationState, newClassListRegData } from "../../../../common/types"

export const clearReRegisteredStudents = (classListObj:{[key: string]: {[key: string]: newClassListRegData}} | undefined, timeBlock:any) => {

    if (classListObj && Object.values(classListObj).length > 0) {
        let allCancelledRegByTime: RegistrationState[] = [];
        let UpdatedReg:RegistrationState[] = []
        Object.values(classListObj[timeBlock]).map((regObj:newClassListRegData) => {
            allCancelledRegByTime = regObj['cancelled'].flat()
            regObj['registrations'].map((updatedKid:any) => {
                UpdatedReg[updatedKid['registrant']['id']] = updatedKid
            })
        })

        let newCancelledList = allCancelledRegByTime.map((cancelledKid:RegistrationState) => {
            if (cancelledKid && UpdatedReg[cancelledKid['registrant']['id']]) {
                return
            } else {
                return cancelledKid
            }
        }).filter(function (element:RegistrationState | undefined) {
            return element !== undefined;
        });

        for(let programType in classListObj[timeBlock]){
            classListObj[timeBlock][programType]['cancelled'] = newCancelledList as RegistrationState[];
        }

        return classListObj
    }
}