import {  RegistrationContent, classListRegistrationState } from "../../../../common/types"

import { sortExtraCare } from "./SortExtraCare";
import { sortByTime } from "./SortClassByTime";
import { createClassListRegCancelledArrays } from "./CreateClassListRegCancelledArrays";
import {  createExtraCareArray } from "./CreateExtraCareArray";
import { clearReRegisteredStudents } from "./ClearReRegisteredStudents";
import { createComboCampRegObject } from "./ComboCampsSorting/createComboCampRegObject";
import { reconfigureRegistrantObject } from "./restructureStudentObject";
import { sortByProgramAndLevel } from "./SortClassByProgramAndLevel";

/**
 * Creates class lists for all times based on the provided registration content.
 *
 * @param registrationContent - An array of registration content.
 * @returns The class list registration object containing class lists for all times.
 */

export const CreateAllTimesClassListsV2 = (registrationContent: RegistrationContent[]) => {
    let classListRegistration:classListRegistrationState = {};
    let newRegistrationObject:RegistrationContent;
    let oldComboRegObject:RegistrationContent;

    let skillTimeGroup:any = [];
    let timeBlocks;
    let extraCare:any;
    let extraCareGroup:any = [];

//     // Get Registrations from API and Convert into new format
        registrationContent.map((registrations: RegistrationContent, index:number) => {
//         // Reconfigure to pass category Time data to student block
        if (
            // Bike Combo Camps
            registrations.categoryTime.id === 4609 ||
            registrations.categoryTime.id === 4740 ||
            registrations.categoryTime.id === 4610 ||
            registrations.categoryTime.id === 4741 ||
            registrations.categoryTime.id === 4348 ||
            // Trail Combo Camps
            registrations.categoryTime.id === 4742 ||
            registrations.categoryTime.id === 4743 ||
            registrations.categoryTime.id === 4813
        ) {
            newRegistrationObject = createComboCampRegObject(registrations, "new");
            oldComboRegObject = createComboCampRegObject(registrations, "old");

            // Sort into AM and PM, duplicating all day kids
            timeBlocks = sortByTime(newRegistrationObject, skillTimeGroup, '12:00', '13:00');
            timeBlocks = sortByTime(oldComboRegObject, skillTimeGroup, '12:00', '13:00');
        } else {
            newRegistrationObject = reconfigureRegistrantObject(registrations);
            // Sort into AM and PM, duplicating all day kids
            timeBlocks = sortByTime(newRegistrationObject, skillTimeGroup, '12:00', '12:30');
        }
        // Sort Extra Care Groups
        extraCare = sortExtraCare(newRegistrationObject, extraCareGroup);
    })

    if (timeBlocks) {
        // Pass Time sorted Classlist to sort by level function
        let amSortedLevels:{
             [key: string]: {[key: string]: RegistrationContent[] }
        };
        let pmSortedLevels:{
            [key: string]: {[key: string]: RegistrationContent[] }
       };

        // Create three objects - sorted Classlists, save Reg Obj, Cancelled kids  
        let amClassLists;
        let pmClassLists;
        let amExtraCare;
        let pmExtraCare;
        if (timeBlocks['am']) {
            amSortedLevels = sortByProgramAndLevel(timeBlocks['am'])
        
            // Create three objects - sorted Classlists, save Reg Obj, Cancelled kids  
            amClassLists = createClassListRegCancelledArrays(amSortedLevels, "am")
            // Clear re-registered students from cancelled list
            amClassLists = clearReRegisteredStudents(amClassLists, 'am')
        }
        if (timeBlocks['pm']) {
            pmSortedLevels = sortByProgramAndLevel(timeBlocks['pm'])
            // Create three objects - sorted Classlists, save Reg Obj, Cancelled kids  
            pmClassLists = createClassListRegCancelledArrays(pmSortedLevels, "pm")
            // // Clear re-registered students from cancelled list
            pmClassLists = clearReRegisteredStudents(pmClassLists, 'pm')
        }
      
        if (extraCare && extraCare['ac']) {
            amExtraCare = createExtraCareArray(extraCare['ac'], 'ac')
        }
        if(extraCare && extraCare['bc']){
            pmExtraCare = createExtraCareArray(extraCare['bc'], 'bc')
        }


        // create Obj with all 4 time periods if they exist to one block to use in components
        classListRegistration = { ...amExtraCare, ...amClassLists, ...pmClassLists, ...pmExtraCare };

    }

    return classListRegistration

}
