
export const DateParsing = (date: string) => {
    let dateToParse;
    if (date.includes('T')) {
        dateToParse = date.split('T')[0]
    } else {
        dateToParse = date
    }
    let time: Date;
    time = new Date(dateToParse + "T00:00:00")
    return time.toDateString()
}


export const TimeParsing = (startTime: string, endTime: string) => {
    let intStartTime = parseInt(startTime)
    let intEndTime = parseInt(endTime)

    if (intStartTime < 12 && intEndTime <= 13) {
        return "AM"
    } else if (intStartTime < 12 && intEndTime > 12) {
        return "AD"
    } else if (intStartTime >= 12 && intEndTime > 12) {
        return "PM"
    }
}
