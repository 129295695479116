import { useState } from "react";

const CampPrintedDocs = () => {
    const [dropDownId, setDropDownId] = useState("");
    const [nestedDropDownId, setNestedDropDownId] = useState("")
    const [secondNestedDropDownId, setSecondNestedDropDownId] = useState("")

    const showDropDown = (e:any) => {
        if (dropDownId === e.target.id) {
            setDropDownId("")
        } else {
            setDropDownId(e.target.id)
        }
    }

    const showNestedDropDown = (e:any) => {
        if (nestedDropDownId === e.target.id) {
            setNestedDropDownId("")
        } else {
            setNestedDropDownId(e.target.id)
        }
    }

    const showSecondNestedDropDown = (e:any) => {
        if (secondNestedDropDownId === e.target.id) {
            setSecondNestedDropDownId("")
        } else {
            setSecondNestedDropDownId(e.target.id)
        }

    }
    return (
        <>
            <h2 className="camp-docs">Camp Documents</h2>

            {/* First Level Buttons */}
            <div className="camp-docs-title-container">
                <h3 className="camp-docs-toggle-titles">Admin Box</h3>
                <button className={dropDownId === "admin-box" ? " button site-toggle-button isOpen" : "button site-toggle-button"} onClick={e => showDropDown(e)} >
                    <img id="admin-box" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="20px" width="20px" />
                </button>
            </div>  
            {/* Second Level Buttons */}
            {dropDownId === "admin-box" &&
                <div className="nested-container">
                    <div className="camp-docs-nested-title-container">
                        <button onClick={e => showNestedDropDown(e)} className={nestedDropDownId === "admin-folder" ? " button site-toggle-button isOpen" : "button site-toggle-button"}>
                            <img id="admin-folder" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="10px" width="10px" />
                        </button>
                        <h4 className="camp-docs-nested-toggle-titles">Administrative Folder</h4>

                    </div>
                    {/* third Level Buttons */}
                    {nestedDropDownId === "admin-folder" &&
                        <>
                            <div className="camp-docs-second-nested-title-container">
                                <h4 id="camp-price-list" onClick={e => showSecondNestedDropDown(e)}>Camp Price Lists 2024</h4>

                            </div >
                            {secondNestedDropDownId === "camp-price-list" &&
                                <ul>
                                    <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Camp%20Price%20Lists%202024/BC%2C%20AB%2C%20MB.pdf" target="_blank" rel="noreferrer noopener">BC, AB, MB (PDF)</a></li>
                                    <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Camp%20Price%20Lists%202024/CA.pdf" target="_blank" rel="noreferrer noopener">CA (PDF)</a></li>
                                    <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Camp%20Price%20Lists%202024/CO%2C%20IL%2C%20OR%2C%20DC.pdf" target="_blank" rel="noreferrer noopener">CO, IL, OR, DC (PDF)</a></li>
                                    <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Camp%20Price%20Lists%202024/ON.pdf" target="_blank" rel="noreferrer noopener">ON (PDF)</a></li>
                                    <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Camp%20Price%20Lists%202024/QC.pdf" target="_blank" rel="noreferrer noopener">QC (PDF)</a></li>
                                    <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Camp%20Price%20Lists%202024/WA%20MA.pdf" target="_blank" rel="noreferrer noopener">WA, MA (PDF)</a></li>
                                </ul>
                            }
                            <ul>
                                <li>
                                    <a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Waiver.pdf" target="_blank" rel="noreferrer noopener">Waiver (PDF)</a>
                                </li>
                            </ul>
                        </>
                    }
                    {/* Second Level Buttons */}
                    <div className="camp-docs-nested-title-container">
                        <button onClick={e => showNestedDropDown(e)} className={nestedDropDownId === "french-documents" ? " button site-toggle-button isOpen" : "button site-toggle-button"}>
                            <img id="french-documents" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="10px" width="10px" />
                        </button>
                        <h4 id="french-documents">French Documents</h4>
                    </div>
                    {nestedDropDownId === "french-documents" &&
                        <ul>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/French%20Documents/Courses%20a%20Obstacles%20Quotidien%20(Daily%20Obstacle%20Course%20(colour%20duotang).pdf" target="_blank" rel="noreferrer noopener">Courses a Obstacles Quotidien (Daily Obstacle Course (colour duotang))</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/French%20Documents/D%C3%A9fi%20du%20Feu%20de%20Circulation%20(Traffic%20Light%20Challenge).pdf" target="_blank" rel="noreferrer noopener">Défi du Feu de Circulation(Traffic Light Challenge)</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/French%20Documents/D%C3%A9fis%20Junior%20Pedalheads%20(JR%20Challenge%20Quick%20Reference).pdf" target="_blank" rel="noreferrer noopener">Défis Junior Pedalheads (JR Challenge Quick Reference)</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/French%20Documents/Feuille%20d'heure%20accumull%C3%A9e%20d'instructeur%20junior%20(Junior%20Instructor%20Tracking%20Sheet).pdf" target="_blank" rel="noreferrer noopener">Feuille d'heure accumulée d'instructeur junior (Junior Instructor Tracking Sheet)</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/French%20Documents/Instruction%20de%20Converge%20(Converge%20Instructions).pdf" target="_blank" rel="noreferrer noopener">Instruction de Converge (Converge Instructions)</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/French%20Documents/Jeux%20du%20Jours%20(L3)%20Livret.pdf" target="_blank" rel="noreferrer noopener">Jeux de Jours (L3) Livret </a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/French%20Documents/Journ%C3%A9e%20th%C3%A9matique%20(Theme%20Day%20Cards).pdf" target="_blank" rel="noreferrer noopener">Journée Thématique (Theme Day Cards)</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/French%20Documents/Journ%C3%A9e%20Th%C3%A9matique%202018%20(2018%20theme%20days).pdf" target="_blank" rel="noreferrer noopener">Journée Thématique 2018 (2018 theme days)</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/French%20Documents/Livret%20d'activit%C3%A9%20Pedalheads%20(Pedalheads%20Activities%20Book).pdf" target="_blank" rel="noreferrer noopener">Livret d'activité Pedalheads (Pedalheads Activities Book)</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/French%20Documents/Renonciation%202018%20(Waiver%202018).pdf" target="_blank" rel="noreferrer noopener">Référence aux Politiques(Quick Reference of Policies)</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/French%20Documents/R%C3%A9f%C3%A9rence%20aux%20Politiques%20(Quick%20Reference%20of%20Policies).pdf" target="_blank" rel="noreferrer noopener">Renonciation 2018 (Waiver 2018)</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/French%20Documents/R%C3%A9vision%20de%20rendement%20Pedalheads%20(Employee%20Performance%20Review).pdf" target="_blank" rel="noreferrer noopener">Révision de rendement Pedalheads (Employment Performance Review)</a></li>
                        </ul>
                    }
                    {/* Second Level Buttons */}
                    <div className="camp-docs-nested-title-container">
                        <button onClick={e => showNestedDropDown(e)} className={nestedDropDownId === "operations-folder" ? " button site-toggle-button isOpen" : "button site-toggle-button"}>
                            <img id="operations-folder" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="10px" width="10px" />
                        </button>
                        <h4 id="operations-folder" onClick={e => showNestedDropDown(e)}>Operations Folder</h4>
                    </div>
                    {nestedDropDownId === "operations-folder" &&
                        <ul>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Operations%20Folder/Biking%20Alternatives.pdf" target="_blank" rel="noreferrer noopener">Biking Alternatives</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Operations%20Folder/Obstacle%20Courses.pdf" target="_blank" rel="noreferrer noopener">Obstable Courses</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Operations%20Folder/Permission%20For%20All%20Day%20Ride%20(BW).pdf" target="_blank" rel="noreferrer noopener">Permission For All Day Ride (BW)</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Operations%20Folder/Procedure%20for%20All%20Day%20Ride.pdf" target="_blank" rel="noreferrer noopener">Procedure For All Day Ride</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Operations%20Folder/Set-Up%20Checklist.pdf" target="_blank" rel="noreferrer noopener">Set-Up Checklist</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Operations%20Folder/Shout%20Out%20Forms.pdf" target="_blank" rel="noreferrer noopener">Shout Out Forms </a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Operations%20Folder/[Final]Camp%20In%20Action%20Checklist.pdf" target="_blank" rel="noreferrer noopener">2024 Camp in Action Checklist </a></li>
                        </ul>
                    }
                    {/* Second Level Buttons */}
                    <div className="camp-docs-nested-title-container">
                        <button onClick={e => showNestedDropDown(e)} className={nestedDropDownId === "sign-in-sheets-folder" ? " button site-toggle-button isOpen" : "button site-toggle-button"}>
                            <img alt="" id="sign-in-sheets-folder" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="10px" width="10px" />
                        </button>
                        <h4>Sign In and Out Sheets Folder</h4>
                    </div>
                    {nestedDropDownId === "sign-in-sheets-folder" &&
                        <ul>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Sign%20In%20and%20Out%20Sheets%20Folder/2023%20Camper%20Sign%20In%20%20Out.pdf" target="_blank" rel="noreferrer noopener">2023 Camper Sign IN/Out</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Sign%20In%20and%20Out%20Sheets%20Folder/Off-Site%20Sign%20Out.pdf" target="_blank" rel="noreferrer noopener">Off-Site Sign Out</a></li>
                        </ul>
                    }
                    {/* Second Level Buttons */}
                    <div className="camp-docs-nested-title-container">
                        <button onClick={e => showNestedDropDown(e)} className={nestedDropDownId === "staffing-folder" ? " button site-toggle-button isOpen" : "button site-toggle-button"}>
                            <img id="staffing-folder" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="10px" width="10px" />
                        </button>
                        <h4 >Staffing Folder</h4>
                    </div>
                    {nestedDropDownId === "staffing-folder" &&
                    <>
                    <div className="camp-docs-second-nested-title-container">
                        <h4 id="hours-reference-sheets" onClick={e => showSecondNestedDropDown(e)} >2024 Hours Reference Sheets</h4>
                    </div>
                     {secondNestedDropDownId === "hours-reference-sheets" &&
                        <ul>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Hours%20Reference%20Sheets%202024/AB%202024%20Hours%20Reference%20Sheet.pdf" target="_blank" rel="noreferrer noopener">AB 2024 Hours Reference Sheet</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Hours%20Reference%20Sheets%202024/BC%202024%20Hours%20Reference%20Sheet.pdf" target="_blank" rel="noreferrer noopener">BC 2024 Hours Reference Sheet</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Hours%20Reference%20Sheets%202024/CA%202024%20Hours%20Reference%20Sheet.pdf" target="_blank" rel="noreferrer noopener">CA 2024 Hours Reference Sheet</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Hours%20Reference%20Sheets%202024/CO%202024%20Hours%20Reference%20Sheet.pdf" target="_blank" rel="noreferrer noopener">CO 2024 Hours Reference Sheet</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Hours%20Reference%20Sheets%202024/DMV%202024%20Hours%20Reference%20Sheet.pdf" target="_blank" rel="noreferrer noopener">DMV 2024 Hours Reference Sheet</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Hours%20Reference%20Sheets%202024/IL%202024%20Hours%20Reference%20Sheet.pdf" target="_blank" rel="noreferrer noopener">IL 2024 Hours Reference Sheet</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Hours%20Reference%20Sheets%202024/MA%202024%20Hours%20Reference%20Sheet.pdf" target="_blank" rel="noreferrer noopener">MA 2024 Hours Reference Sheet</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Hours%20Reference%20Sheets%202024/MB%202024%20Hours%20Reference%20Sheet.pdf" target="_blank" rel="noreferrer noopener">MB 2024 Hours Reference Sheet</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Hours%20Reference%20Sheets%202024/ON%202024%20Hours%20Reference%20Sheet.pdf" target="_blank" rel="noreferrer noopener">ON 2024 Hours Reference Sheet</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Hours%20Reference%20Sheets%202024/OR%202024%20Hours%20Reference%20Sheet.pdf" target="_blank" rel="noreferrer noopener">OR 2024 Hours Reference Sheet</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Hours%20Reference%20Sheets%202024/QC%202024%20Hours%20Reference%20Sheet.pdf" target="_blank" rel="noreferrer noopener">QC 2024 Hours Reference Sheet</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Hours%20Reference%20Sheets%202024/WA%202024%20Hours%20Reference%20Sheet.pdf" target="_blank" rel="noreferrer noopener">WA 2024 Hours Reference Sheet</a></li>

                        </ul>
                     }
                        <ul>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Bike%20Shadow%20Training%202022.pdf" target="_blank" rel="noreferrer noopener">Bike Shadow Training 2022</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/Employee%20Performance%20Review%20-%20Pedalheads.pdf" target="_blank" rel="noreferrer noopener">Employee Performance Review - Pedalheads</a></li>
                            
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/JI%20Scavenger%20Hunt%20%26%20Tracking%20Sheet.pdf" target="_blank" rel="noreferrer noopener">JI Scavenger Hunt & Tracking Sheet</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/2024%20Pedalheads%20Daily%20Hours%20&%20Breaks%20Record.pdf" target="_blank" rel="noreferrer noopener">Daily Hours & Breaks Record</a></li>
                        </ul>
                        
                     </>
                    }
                </div>
                
            }
            {/* First Level Buttons */}
            <div className="camp-docs-title-container">
                <h3 className="camp-docs-toggle-titles">Arts & Crafts Staff Box</h3>
                <button className={dropDownId === "arts-crafts" ? " button site-toggle-button isOpen" : "button site-toggle-button"} onClick={e => showDropDown(e)} >
                    <img id="arts-crafts" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="20px" width="20px" />
                </button>
            </div>
            {/* Second Level Buttons */}
            {dropDownId === "arts-crafts" &&
                <div className="nested-container">
                    <ul>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Arts%20%26%20Crafts%20Staff%20Box/Craft%20Manual%202022.pdf" target="_blank" rel="noreferrer noopener">Craft Manual 2022 (PDF)</a></li>
                    </ul>
                </div>
            }
            {/* First Level Buttons */}
            <div className="camp-docs-title-container">
                <h3 className="camp-docs-toggle-titles">Biking Alternatives Bin</h3>
                <button className={dropDownId === "biking-alternatives" ? " button site-toggle-button isOpen" : "button site-toggle-button"} onClick={e => showDropDown(e)} >
                    <img id="biking-alternatives" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="20px" width="20px" />
                </button>
            </div>
            {/* Second Level Buttons */}

            {dropDownId === "biking-alternatives" &&
                <div className="nested-container">
                    <ul>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Biking%20Alternatives%20Bin/Before%20and%20After%20Care%20Guide%202022.pdf" target="_blank" rel="noreferrer noopener" >Before and After Care Guide 2022</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Biking%20Alternatives%20Bin/Bike%20Storybook%20Activities.pdf" target="_blank" rel="noreferrer noopener" >Bike Storybook Activities</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Biking%20Alternatives%20Bin/Colouring%20Sheet%201.pdf" target="_blank" rel="noreferrer noopener" >Colouring Sheet 1</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Biking%20Alternatives%20Bin/Colouring%20Sheet%202.pdf" target="_blank" rel="noreferrer noopener" >Colouring Sheet 2</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Biking%20Alternatives%20Bin/Fortune%20Teller%20Instructions%20(x2%20laminated).pdf" target="_blank" rel="noreferrer noopener" >Fortune Teller Instructions (x2 laminated)</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Biking%20Alternatives%20Bin/Nature%20Scavenger%20Hunt%20(x2%20laminated).pdf" target="_blank" rel="noreferrer noopener" >Nature Scavenger Hunt (x2 laminated)</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Biking%20Alternatives%20Bin/Paper%20Airplane%20Instructions%20(x2%20laminated).pdf" target="_blank" rel="noreferrer noopener" >Paper Airplane Instructuions (x2 laminated)</a></li>
                    </ul>
                </div>
            }
            {/* First Level Buttons */}
            <div className="camp-docs-title-container">
                <h3 className="camp-docs-toggle-titles">Clipboards</h3>
                <button className={dropDownId === "clipboards" ? " button site-toggle-button isOpen" : "button site-toggle-button"} onClick={e => showDropDown(e)} >
                    <img id="clipboards" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="20px" width="20px" />
                </button>
            </div>
            {/* Second Level Buttons */}
            {dropDownId === "clipboards" &&
                <div className="nested-container">
                    <ul>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Clipboards/Pedalheads%20Camp%20Rules%202022.pdf" target="_blank" rel="noreferrer noopener" >Pedalheads Camp Rules 2022</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Clipboards/Road%20Riding%20Checklist%20NEW.pdf" target="_blank" rel="noreferrer noopener" >Road Riding Checklist</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Clipboards/Station%20Rotation%20Simple%20v2.pdf" target="_blank" rel="noreferrer noopener" >Station Rotation Simple V2</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Clipboards/Bike%20Handling%20and%20Control%20NEW.pdf" target="_blank" rel="noreferrer noopener" >THe PH Method - Bike Handling & Control</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Clipboards/Learn%20to%20Ride%20NEW.pdf" target="_blank" rel="noreferrer noopener" >THe PH Method - Learn to Ride</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Clipboards/Road%20Riding%20Method%20NEW.pdf" target="_blank" rel="noreferrer noopener" >THe PH Method - Road Riding</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Clipboards/Treasure%20Hunt%20Tuesday.pdf" target="_blank" rel="noreferrer noopener" >Treasure Hunt Tuesday</a></li>
                    </ul>
                </div>
            }
            {/* First Level Buttons */}
            <div className="camp-docs-title-container">
                <h3 className="camp-docs-toggle-titles">First-Aid Kit</h3>
                <button className={dropDownId === "first-aid" ? " button site-toggle-button isOpen" : "button site-toggle-button"} onClick={e => showDropDown(e)} >
                    <img id="first-aid" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="20px" width="20px" />
                </button>
            </div>
            {/* Second Level Buttons */}
            {dropDownId === "first-aid" &&
                <div className="nested-container">
                    <ul>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/First%20Aid%20Kit/First%20Aid%20How-Tos.pdf" target="_blank" rel="noreferrer noopener" >First Aid How-Tos</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/First%20Aid%20Kit/Pedalheads%20Resource%20Card%20(6%20per%20page%2C%20cut%20and%20laminate).pdf" target="_blank" rel="noreferrer noopener" >Pedalheads Resource Card (6 per page, cut and laminate)</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/First%20Aid%20Kit/Parked%20Car%20Collision.pdf" target="_blank" rel="noreferrer noopener" >Parked Car Collision</a></li>
                    </ul>
                </div>
            }
            {/* First Level Buttons */}
            <div className="camp-docs-title-container">
                <h3 className="camp-docs-toggle-titles">Fun & Games</h3>
                <button className={dropDownId === "fun-games" ? " button site-toggle-button isOpen" : "button site-toggle-button"} onClick={e => showDropDown(e)} >
                    <img id="fun-games" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="20px" width="20px" />
                </button>
            </div>
            {/* Second Level Buttons */}
            {dropDownId === "fun-games" &&
                <div className="nested-container">
                    <ul>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Fun%20%26%20Games/Parachute%20Games.pdf" target="_blank" rel="noreferrer noopener" >Parachute Games</a></li>

                    </ul>
                </div>
            }
            {/* First Level Buttons */}
            <div className="camp-docs-title-container">
                <h3 className="camp-docs-toggle-titles">Instructor Admin Box</h3>
                <button className={dropDownId === "instructor-admin" ? " button site-toggle-button isOpen" : "button site-toggle-button"} onClick={e => showDropDown(e)} >
                    <img id="instructor-admin" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="20px" width="20px" />
                </button>
            </div>
            {/* Second Level Buttons */}
            {dropDownId === "instructor-admin" &&
                <div className="nested-container">
                    <ul>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Instructor%20Admin%20Box/Anatomy%20of%20a%20Bicycle%202023%20(x1%20Laminated).pdf" target="_blank" rel="noreferrer noopener" >Anatomy of a Bicylce 2023 (x1 laminated)</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Instructor%20Admin%20Box/Bike%20Maintenance%20How-To.pdf" target="_blank" rel="noreferrer noopener" >Bike Maintenance How-To</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Instructor%20Admin%20Box/Junior%20Pedalheads%20Challenge.pdf" target="_blank" rel="noreferrer noopener" >Junior Pedalheads Challenge</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Instructor%20Admin%20Box/Primetime%20Information.pdf" target="_blank" rel="noreferrer noopener" >Primetime Information</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Instructor%20Admin%20Box/Road%20Sign%20Sets%20(laminated).pdf" target="_blank" rel="noreferrer noopener" >Road Sign Sets (laminated)</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Instructor%20Admin%20Box/Senior%20Pedalheads%20Challenge.pdf" target="_blank" rel="noreferrer noopener" >Senior Pedalheads Challenge</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Instructor%20Admin%20Box/Treasure%20Hunt%20Tuesday.pdf" target="_blank" rel="noreferrer noopener" >Treasure Hunt Tuesday</a></li>

                    </ul>
                </div>
            }
            {/* First Level Buttons */}
            <div className="camp-docs-title-container">
                <h3 className="camp-docs-toggle-titles">Preschool Bin</h3>
                <button className={dropDownId === "preschool-bin" ? " button site-toggle-button isOpen" : "button site-toggle-button"} onClick={e => showDropDown(e)} >
                    <img id="preschool-bin" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="20px" width="20px" />
                </button>
            </div>
            {/* Second Level Buttons */}
            {dropDownId === "preschool-bin" &&
                <div className="nested-container">
                    <ul>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Preschool%20Bin/Grand%20Prix%20Race%20Numbers.pdf" target="_blank" rel="noreferrer noopener" >Grand Prix Race Numbers</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Preschool%20Bin/Preschool%20Guide%202022.pdf" target="_blank" rel="noreferrer noopener" >Preschool Guide 2022</a></li>
                    </ul>
                </div>
            }
            {/* First Level Buttons */}
            <div className="camp-docs-title-container">
                <h3 className="camp-docs-toggle-titles">Support Kit</h3>
                <button className={dropDownId === "support-kit" ? " button site-toggle-button isOpen" : "button site-toggle-button"} onClick={e => showDropDown(e)} >
                    <img id="support-kit" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="20px" width="20px" />
                </button>
            </div>
            {/* Second Level Buttons */}
            {dropDownId === "support-kit" &&
                <div className="nested-container">
                    <ul>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Support%20Kit/Activity%20Cards.pdf" target="_blank" rel="noreferrer noopener" >Activity Cards</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Support%20Kit/Daily%20Schedule.pdf" target="_blank" rel="noreferrer noopener" >Daily Schedule</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Support%20Kit/Feeling%20Cards.pdf" target="_blank" rel="noreferrer noopener" >Feeling Cards</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Support%20Kit/First%20Next%20Then.pdf" target="_blank" rel="noreferrer noopener" >First Next Then</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Support%20Kit/PECS%20Document.pdf" target="_blank" rel="noreferrer noopener" >PECS Document</a></li>
                        <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Support%20Kit/Tips%20%26%20Tricks.pdf" target="_blank" rel="noreferrer noopener" >Tips & Tricks</a></li>
                    </ul>
                </div>
            }
            {/* First Level Buttons */}
            <div className="camp-docs-title-container">
                <h3 className="camp-docs-toggle-titles">Trail</h3>
                <button className={dropDownId === "trail" ? " button site-toggle-button isOpen" : "button site-toggle-button"} onClick={e => showDropDown(e)} >
                    <img id="trail" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="20px" width="20px" />
                </button>
            </div>
            {/* Second Level Buttons */}
            {dropDownId === "trail" &&
                <div className="nested-container">
                    <div className="camp-docs-nested-title-container">
                        <button onClick={e => showNestedDropDown(e)} className={nestedDropDownId === "instructor-first-aid-kit" ? " button site-toggle-button isOpen" : "button site-toggle-button"}>
                            <img id="instructor-first-aid-kit" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="10px" width="10px" />
                        </button>
                        <h4>Instructor First Aid Kit (1 per 2 Instructor)</h4>
                    </div>
                    {nestedDropDownId === "instructor-first-aid-kit" &&
                        <ul>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Trail/Instructor%20First%20Aid%20Kit%20(1%20per%202%20Instructor)/First%20Aid%20How-Tos.pdf" target="_blank" rel="noreferrer noopener">First Aid How-Tos</a></li>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Trail/Instructor%20First%20Aid%20Kit%20(1%20per%202%20Instructor)/Trail%20Missing%20Child%20Cards%202023.pdf" target="_blank" rel="noreferrer noopener">Trail Missing Child Cards 2023</a></li>

                        </ul>
                    }
                    <div className="camp-docs-nested-title-container">
                        <button id="trail-equipment-bin" onClick={e => showNestedDropDown(e)} className={nestedDropDownId === "trail-equipment-bin" ? " button site-toggle-button isOpen" : "button site-toggle-button"}>
                            <img id="trail-equipment-bin" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="10px" width="10px" />
                        </button>
                        <h4  >Replenishment Items (first aid) - To go in Trail Equipment Bin</h4>
                    </div>
                    {nestedDropDownId === "trail-equipment-bin" &&
                        <ul>
                            <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Trail/Replenishment%20Items%20(first%20aid)-%20to%20go%20in%20Trail%20Equipment%20Bin/Trail%20Missing%20Child%20Cards%202023.pdf" target="_blank" rel="noreferrer noopener">Trail Missing Child Cards 2023</a></li>

                        </ul>
                    }
                    <div className="camp-docs-nested-title-container">
                        <button id="trail-admin" onClick={e => showNestedDropDown(e)} className={nestedDropDownId === "trail-admin" ? " button site-toggle-button isOpen" : "button site-toggle-button"}>
                            <img id="trail-admin" src="https://cdn.pedalheads.com/images/icons/velo-dropdown-arrow.svg" height="10px" width="10px" />
                        </button>
                        <h4>Trail Admin</h4>
                    </div>
                    {nestedDropDownId === "trail-admin" &&
                        <>
                            <div className="camp-docs-second-nested-title-container">
                                <h4 id="trail-camp-price-list" onClick={e => showSecondNestedDropDown(e)} >Camp Price Lists 2024</h4>

                            </div>
                            {secondNestedDropDownId === "trail-camp-price-list" &&
                                <ul>
                                    <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Camp%20Price%20Lists%202024/BC%2C%20AB%2C%20MB.pdf" target="_blank" rel="noreferrer noopener">BC, AB, MB (PDF)</a></li>
                                    <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Camp%20Price%20Lists%202024/CA.pdf" target="_blank" rel="noreferrer noopener">CA (PDF)</a></li>
                                    <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Camp%20Price%20Lists%202024/CO%2C%20IL%2C%20OR%2C%20DC.pdf" target="_blank" rel="noreferrer noopener">CO, IL, OR, DC (PDF)</a></li>
                                    <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Camp%20Price%20Lists%202024/ON.pdf" target="_blank" rel="noreferrer noopener">ON (PDF)</a></li>
                                    <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Camp%20Price%20Lists%202024/QC.pdf" target="_blank" rel="noreferrer noopener">QC (PDF)</a></li>
                                    <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Administrative%20Folder/Camp%20Price%20Lists%202024/WA%20MA.pdf" target="_blank" rel="noreferrer noopener">WA, MA (PDF)</a></li>
                                </ul>
                            }
                             
                            <ul>
                                <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Trail/Trail%20Admin/2023%20Camper%20Sign%20In%20%20Out.pdf" target="_blank" rel="noreferrer noopener">2023 Camper Sign IN/Out</a></li>
                                <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Trail/Trail%20Admin/2024%20Trail%20Manager%20Checklist.pdf" target="_blank" rel="noreferrer noopener">2024 Trail Manager Checklist</a></li>
                                <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Trail/Trail%20Admin/Bike%20Maintenance%20How-To.pdf">Bike Maintenance How-To</a></li>
                                <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Admin%20Box/Staffing%20Folder/2024%20Pedalheads%20Daily%20Hours%20&%20Breaks%20Record.pdf" target="_blank" rel="noreferrer noopener">Daily Hours & Breaks Record</a></li>
                                <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Trail/Trail%20Admin/Employee%20Performance%20Review.pdf" target="_blank" rel="noreferrer noopener">Employee Performance Review</a></li>
                                <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Trail/Trail%20Admin/Shout%20Out%20Forms.pdf" target="_blank" rel="noreferrer noopener">Shout Out Forms</a></li>
                                <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Trail/Trail%20Admin/Trail%20Instructor%20Shadow%20Training.pdf" target="_blank" rel="noreferrer noopener">Trail Instructor Shadow Training</a></li>
                                <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Trail/Trail%20Admin/Trail%20Riding%20Checklist%20NEW.pdf" target="_blank" rel="noreferrer noopener">Trail Riding Checklist & Station Rotation </a></li>
                                <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Trail/Trail%20Admin/Waiver.pdf" target="_blank" rel="noreferrer noopener">Waiver</a></li>
                                <li><a href="https://cdn.pedalheads.com/docs/velo-camp-docs/Trail/Trail%20Admin/Trail%20Etiquette%20&%20Camp%20Rules%20Combo.pdf" target="_blank" rel="noreferrer noopener">Trail Etiquette & PH Camp Rules</a></li>
                            </ul>
                        </>
                    }
                </div>
            }
        </>
    )
}

export default CampPrintedDocs;