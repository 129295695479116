import { Droppable, Draggable } from "react-beautiful-dnd";
import { DragDropProps } from '../common/types';
import StudentInfoCard from "../features/ClassListsFeatures/StudentInfoCard";

// Functions
import { getItemStyle, getListStyle } from "../functions/ClassListsFeatures/ClassDragAndDropFunctions/DragAndDropFunctions"
import ConeList from "../features/ClassListsFeatures/CreateConeInput";
import InstructorList from '../features/InstructorFeatures/InstructorList';
import { groupNumbersByCat } from "../functions/ClassListsFeatures/ClassListValidationFunctions/classListErrorContent";

const DragDropComponent = ({ handleStateArrayUpdate, classList, classGroupVisible, droppableId, index, instructors, setInstructors, cones, setCones, error, updatedTimeStamp, programType }: DragDropProps) => {

	let skillCatId = classList[0] && classList[0]["skillCategory" as keyof typeof classList[0]] && (classList[0]["skillCategory" as keyof typeof classList[0]]["id"] as string)?.toString()
	let groupingNum = groupNumbersByCat[skillCatId as keyof typeof groupNumbersByCat] && groupNumbersByCat[skillCatId as keyof typeof groupNumbersByCat]['maxCampers'] ? groupNumbersByCat[skillCatId as keyof typeof groupNumbersByCat]['maxCampers'] : 5
	let programTypeCol = programType.includes("Swim") ? `${classList[0] && classList[0]["categoryTime" as keyof typeof classList[0]] && (classList[0]["categoryTime" as keyof typeof classList[0]]["startTime"] as string)}` : `Class ${index + 1}`
	const createCamperNum = (classGroupVisible:string, classListLength:number) => {
		let classValues = ""
		if (classGroupVisible === "bc" || classGroupVisible === "ac") {
			classValues = classListLength + `/8`
		}
		if (classGroupVisible === "am" || classGroupVisible === "pm") {
			classValues = classListLength + `/${groupingNum}`
		}
		return classValues
	}

	return (
		<div className="class-list-level">
			<Droppable key={index} droppableId={`${droppableId}`}>

				{(provided, snapshot) => (

					<div className={"container-class-volume" + Object.keys(classList).length + (error && error[index] ? " class-has-error" : "")}>

						<h2>{programTypeCol} - <span className={"class-volume class-volume" + Object.keys(classList).length + (error && error[index] ? " class-has-error" : "")}> {createCamperNum(classGroupVisible, Object.keys(classList).length)}</span></h2>
						<InstructorList
							index={index}
							classGroupVisible={classGroupVisible}
							instructors={instructors}
							setInstructors={setInstructors}
							handleStateArrayUpdate={handleStateArrayUpdate}
							programType={programType}
						/>
						<ConeList
							index={index}
							classGroupVisible={classGroupVisible}
							cones={cones}
							setCones={setCones}
							handleStateArrayUpdate={handleStateArrayUpdate}
							programType={programType}
						/>

						<div
							className={`student-section-block student-column-${classGroupVisible} student-column${Object.keys(classList).length}`}
							style={getListStyle(snapshot.isDraggingOver)}
							ref={provided.innerRef}
							{...provided.droppableProps}>
							{Object.entries(classList).map((student: Array<any>, index: number) => {
								return (
									<Draggable
										key={student[1]['id']}
										draggableId={`${student[1]['id']}`}
										index={index}
									>
										{(provided, snapshot) => (
											<div
												className={`draggable-style-div ${Date.parse(student[1]['created']) > Date.parse(updatedTimeStamp) ? "new-student" : ""} `}
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style
												)}
											>
												<StudentInfoCard student={student} />

											</div>

										)}
									</Draggable>)
							})}
							{provided.placeholder}
						</div>
					</div>
				)
				}
			</Droppable>
		</div>
	)
}

export default DragDropComponent;