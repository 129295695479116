import React from "react"

const ClassListErrorDisplay = ({ error }: {error: string[][]}) => {

    return (
        <div className="classlist-error-display">
            {error && Object.entries(error).map((errorbyTime: any, index:number) => {
                if (Object.keys(errorbyTime[1]).length > 0) {
                    return (
                        <React.Fragment key={index}>
                            {Object.entries(errorbyTime[1]).map((errorByProgram:any, index:number) =>{
                            return (
                                <div className="alert-container" key={index}>
                                    <h4>Errors in {errorbyTime[0]} - {errorByProgram[0]}  Program:</h4>
                                    {Object.entries(errorByProgram[1]).map((errorbyClass: any, index:number) => {
                                        return (
                                            <p key={index}>{errorbyClass[1]}</p>
                                        )
                                      
                                    })

                                    }

                                </div>
                            )
                        })}</React.Fragment>
                    )
                }
            })}
        </div>
    )
}

export default ClassListErrorDisplay;