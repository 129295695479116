import { tableFooterProps } from "../common/types"

function TableFooter({Allocated, Percentage, Registered}:tableFooterProps){
    return(
    <tr>
        <td key="empty-0"></td>
        <td key="empty-1"></td>
        <td key={`allocated-${Allocated}`}>{Allocated}</td>
        <td key={`registered-${Registered}`}>{Registered}</td>
        <td key={`percentage-${Percentage}`}>{Percentage}%</td>
        <td key="empty-2"></td>
        <td key="empty-3"></td>
        <td key="empty-4"></td>
    </tr>
    )
}

export default TableFooter