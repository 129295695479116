import { RegistrationState, newClassListRegData, newClassListRegistrations } from "../../../../common/types";

export const updateStudentInfo = (UpdatedReg:RegistrationState[], currentClass:newClassListRegData) => {
    // object to store the new array 
    let newClassArray;

    // Copy of current class to be able to update
    let currentClassCopy = {...currentClass }

    let updatedClassList = []

    // loop the arrays of classlists for the timeblock ie AM 0 -> 10 Classes <Array<object>>
    if(Object.keys(currentClassCopy).length > 0) {
        newClassArray = currentClassCopy['classLists'].map((classes:any, classIndex:number) => {
            return updatedClassList = classes.map((students:any, index:number) => {
                if (UpdatedReg[students['id']]) {
                    return UpdatedReg[students['id']]
                } else {
                    return
                }
                // remove empty kids who were previously filtered out as cancelled regs
            }).filter(function (element:any) {
                return element !== undefined;
            });

        })
    }else{
        newClassArray = {}
    }
    return newClassArray
}